<template>
    <section id="cart" v-if="user">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <h2>Личный кабинет</h2>
                </div>
                <!-- <pre>{{user}}</pre> -->
                <div class="col-lg-4">
                    <p class="grey-txt mb-10">
                        Имя пользователя
                    </p>
                    <h3>{{user.user_display_name}}</h3>
                    <br>
                    <p class="grey-txt mb-10">
                        E-mail
                    </p>
                    <h3>{{user.user_email}}</h3>
                    <br>
                    <p class="grey-txt mb-10">
                        Сумма скидки
                    </p>
                    <h3>0%</h3>
                    <br>
                </div>


                <div class="col-lg-8">
                    <div class="deliv-btns">
                        <button class="deliv-btn" v-for="(item, index) in tabs" :key="index"
                        :class="{'active-delivery': item.active}" @click="changeTab(index)">
                            {{item.name}}
                        </button>
                    </div>

                    <div class="div" v-if="tabs[0].active">
                        <p class="grey-txt" v-if="!user.sales || !user.sales.length">У вас еще нет заказов</p>
                        <History v-else :sales="user.sales" />
                    </div>
                    <div class="div" v-if="tabs[1].active">
                        <p class="grey-txt" v-if="!wishlist.length">У вас еще нет избранного</p>
                        <Wishlist :wishlist="wishlist" />
                    </div>

                    
                </div>
                

                <div class="col-lg-12">
                    <button class="to-cart" style="margin-top:30px;" @click="signOut">Выйти из личного кабинета</button>
                </div>




                
            </div>
        </div>
    </section>
</template>

<script>
import { mapGetters } from 'vuex'
import History from '../components/ui/History.vue'
import Wishlist from '../components/ui/Wishlist.vue'

export default {
    components: {History, Wishlist},
    data(){
        return{
            tabs: [
                {
                    name: 'История заказов',
                    active: true
                },
                {
                    name: 'Избранное',
                    active: false
                },
            ]
        }
    },
    methods: {
        changeTab(index){
            this.tabs.forEach(item =>{
                item.active = false
            })
            this.tabs[index].active = true
        },
        signOut(){
            this.$store.dispatch('auth/SIGN_OUT').then(() => {
        		this.$router.replace("/enter");
			});
        }
    },
    computed: {
        ...mapGetters({
            user: "auth/getAuthenticated",
            wishlist: "auth/getLikes"
        })
    }
}
</script>