import axios from 'axios'
import { API } from '../config';

const cart = {
	namespaced: true,
	state: {
        cart: [],
		ablePromo: true,
		salePerc: '0',
		delivery: [],
		promoCodeName: '',
		certSaleAmount: 0
  	},
	mutations: {
		HIDE_PROMO(state, perc){
			state.salePerc = perc
			state.ablePromo = false
		},
		SHOW_PROMO(state, perc){
			state.salePerc = '0'
			state.ablePromo = true
		},
		SET_HID_PROMO(state, hiddenPromo){
			state.salePerc = hiddenPromo.salePerc
			state.ablePromo = hiddenPromo.ablePromo
		},
		ADD_TO_CART(state, good){
            state.cart.push(good)
        },
        ADD_QUANTITY(state, goodInCart){	
			let productInCart = state.cart.find(item =>{
				return  item.id == goodInCart.id
			})
			productInCart.amount++
		},
		ADD_SEVERAL(state, updateCart){
			
			let productInCart = state.cart.find(item =>{
				return  item.id == updateCart.good.id
			})
			productInCart.amount += updateCart.amount

		},
		CHANGE_AMOUNT_PLUS(state, cartArr){
			state.cart = []
			state.cart = cartArr
		},
		CHANGE_AMOUNT_MINUS(state, cartArr){
			state.cart = []
			state.cart = cartArr
		},
		DELETE_CART(state, ind){
			state.cart.splice(ind, 1)
		},
		CLEAR_CART(state){
			state.cart = []
		},
		UPDATE_CART(state, cart){
			state.cart = cart
		},
		CART_VALIDATE(state, cart){
			state.cart = cart
		},
		SET_DELIVERY(state, delivery){
			state.delivery = delivery
		},
		SET_PROMO_NAME(state, name){
			state.promoCodeName = name
		}
	},
	actions: {
		async addFromSingle({commit, state, rootState}, param){
			let loc = ''
			let cartArr = state.cart
			let goodInCart = cartArr.find(item => {
				return item.id == param.good.id 
			})
			console.log(param)
			
			// товара нет, добавляем новый
			if(!goodInCart){
				let newGood = param.good
				newGood.amount = param.amount
				cartArr.push(newGood)
				state.cart = []
				await commit("UPDATE_CART", cartArr)
				localStorage.setItem("cart_new", JSON.stringify(state.cart));
				return 'success'
			}

			// проверяем регион
			if(rootState.catalog.region === 'Санкт-Петербург и ЛО (самовывоз на весну)'){
				loc = 'sl'
			}
			if(rootState.catalog.region === 'Регионы (рассылка на август)'){	
				loc = 'rg'
			}
	
			let existingGood = state.cart.find(item =>{
				return item.id == param.good.id
			})
				
				let quote = existingGood.quantity[loc]
				let futureVal = existingGood.amount + param.amount
				
				// if(futureVal > quote){
				// 	// квота превышена
				// 	return 'error'
				// }
			
				existingGood.amount += param.amount
				state.cart = []
				await commit("UPDATE_CART", cartArr)
				localStorage.setItem("cart_new", JSON.stringify(state.cart));
				return 'success'


		},

		// ENDDDD добавление
		async changeAmountPlus({commit, state}, good){
			var cartArr = state.cart
			let prodInCart = cartArr.find(item =>{
				return item.id == good.id
			})

			if(prodInCart){
				prodInCart.amount++
				await commit("CHANGE_AMOUNT_PLUS", cartArr)
				localStorage.setItem("cart_new", JSON.stringify(state.cart));
			}else{
				return
			}
		},
		async changeAmountMinus({commit, state}, param){
			
			var cartArr = state.cart
			let prodInCart = cartArr.find(item =>{
				return item.id == param.item.id
			})

			if(prodInCart){
				if(prodInCart.amount == 1){
					cartArr.splice(param.index, 1)
					await commit("CHANGE_AMOUNT_MINUS", cartArr)
					localStorage.setItem("cart_new", JSON.stringify(state.cart));
				}else{
					prodInCart.amount--
					await commit("CHANGE_AMOUNT_MINUS", cartArr)
					localStorage.setItem("cart_new", JSON.stringify(state.cart));
				}
			}else{
				return
			}
		},
		async deleteCart({commit, state}, ind){
			await commit("DELETE_CART", ind)
			localStorage.setItem("cart_new", JSON.stringify(state.cart));
		},
		async clearCart({commit, state}){
			await commit("CLEAR_CART")
			await commit("SHOW_PROMO")
			let hiddenPromo = {
				ablePromo: state.ablePromo,
				salePerc: state.salePerc
			}
			localStorage.setItem("hiddenPromo", JSON.stringify(hiddenPromo));
			localStorage.setItem("cart_new", JSON.stringify(state.cart));
		},
		async applyPromo({commit, state}, catalog){
			var cartArr = state.cart
			if(catalog.region === 'Регионы (рассылка на август)'){
				cartArr.forEach(item =>{
					item.price.rg = (item.price.rg) - (item.price.rg / 100 * parseInt(catalog.percent))
				})
				await commit("UPDATE_CART", cartArr)
				await commit("HIDE_PROMO", catalog.percent)
				await commit("SET_PROMO_NAME", catalog.promoCodeName)
				let hiddenPromo = {
					ablePromo: state.ablePromo,
					salePerc: state.salePerc
				}
				localStorage.setItem("hiddenPromo", JSON.stringify(hiddenPromo));
				localStorage.setItem("cart_new", JSON.stringify(state.cart));
			}else{
				cartArr.forEach(item =>{
					item.price.sl = (item.price.sl) - (item.price.sl / 100 * parseInt(catalog.percent))
				})
				await commit("UPDATE_CART", cartArr)
				await commit("HIDE_PROMO", catalog.percent)
				await commit("SET_PROMO_NAME", catalog.promoCodeName)
				let hiddenPromo = {
					ablePromo: state.ablePromo,
					salePerc: state.salePerc
				}
				localStorage.setItem("hiddenPromo", JSON.stringify(hiddenPromo));
				localStorage.setItem("cart_new", JSON.stringify(state.cart));
			}
		},
		VALIDATE_HID_PROMO({commit}, hiddenPromo){
			if(!hiddenPromo){
				return
			}else{
				commit("SET_HID_PROMO", hiddenPromo)
			}
		},
		applyCert({state}, catalog){
			state.certSaleAmount = catalog.amount
			state.ablePromo = false
			state.promoCodeName = catalog.promoCodeName
		},
		clearSert({state}){
			state.certSaleAmount = 0
			state.ablePromo = true
		},
		VALIDATE_CART({commit}, cart){
			if(!cart){
				return
			}
			commit("CART_VALIDATE", cart)
		},
		loadDelivery({commit}){
			axios
			.get(`${API}get/delivery/`)
			.then(res =>{
				commit("SET_DELIVERY", res.data)
			})
		},
		rebuildCart({commit, state}, id){
			let newCart = [...state.cart]
			state.cart = newCart.filter(i => i.id !== id)
			localStorage.setItem("cart_new", JSON.stringify(state.cart));
		}
	},
	getters: {
       getCart(state){
           return state.cart
       },
	   	getAblePromo(state){
			   return state.ablePromo	   
		},
		getSalePerc(state){
			return state.salePerc	   
		},
		getDelivery(state){
			return state.delivery
		},
		getPromoName(state){
			return state.promoCodeName
		},
		getCertSaleAmount(state){
			return state.certSaleAmount
		}
	}
}

export default cart


