<template>
    <section id="news" v-if="news.length">
        <!-- <pre>{{news}}</pre> -->
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <swiper ref="mySwiper3" :options="swiperOptions">
                    <swiper-slide v-for="(slide, index) in news" :key="index">
                        <a :href="`https://vk.com/sever.flora?w=wall-38046188_${slide.id}`" target="_blank">
                            <div class="news-slide" >                    
                                <div class="news-slide-img"
                                v-lazy:background-image="slide.image[0]"
                                ></div>
                                <div class="news-slide-body">
                                    <img src="../assets/img/vklogo.svg" class="vk-logo">
                                    <p class="black-txt">{{slide.descr.substr(0, 180)}}...</p>
                                </div>
                            </div>
                        </a>
                    </swiper-slide>
                </swiper> 
                
                <div class="prevBtn newsPrev newsPrev1"></div>
                <div class="nextBtn newsNext newsNext1"></div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    computed: {
        ...mapGetters({
            news: "content/getNews"
        })
    },
    data(){
        return{
            swiperOptions: {
                slidesPerView: 4,
                spaceBetween: 30,
                draggable: false,
                parallax: true,
                loop: true,    
                infinite: true,
                speed: 300,
                navigation: {
                    prevEl: '.newsPrev1',
                    nextEl: '.newsNext1'
                },
                breakpoints: {
			            320: {
			              slidesPerView: 1.2,
			              spaceBetween: 10
			            },
			            768: {
				          slidesPerView: 2,
			              spaceBetween: 20,
				        },
				        1150: {
				          slidesPerView: 4,
			              spaceBetween: 30,
				        }
			        }
            },
        }
    }
}
</script>