<template>
    <section id="main">

        <!-- <div class="social-sidebar"> -->
            <!-- <a href="https://www.instagram.com/sever.flora/" target="blank_">
                <img src="@/assets/img/winst.svg" alt="">
            </a> -->
            <!-- <a href="https://wa.me/79213321380" target="blank_">
                <img src="@/assets/img/wwh.svg" alt="">
            </a>
            <a href="https://vk.com/sever.flora" target="blank_">
                <img src="@/assets/img/wvk.svg" alt="">
            </a>
        </div> -->

        <div class="pop-search" v-if="openSearch" @click="closeSearch">
            <div class="pop-search-box" @click.stop>
                <label for="">Введите параметры для поиска
                    <button v-if="search !== '' " class="clear-btn" @click="clearSearch">Очистить</button> </label>
                <div class="pay-prices">
                    <input type="search" placeholder="Поиск" v-model="search" @input="goSearch">
                    <button class="to-cart">Поиск</button>
                </div>
                <!-- <pre>{{searchResults}}</pre> -->
                <div class="search-results" v-if="searchResults && searchResults.length">
                    <router-link tag="div" @click.native="closeSearch" class="cat-title"
                        v-for="(link, index) in searchResults" :key="index" :to="`/product/${link._embedded.self[0].slug}`">
                        <!-- <img :src="link._embedded.self[0].yoast_head_json.og_image[0].url" alt=""> -->
                        <h5>{{link.title}}</h5>
                    </router-link>
                </div>

                <div class="search-results" v-if="searchResults && !searchResults.length">
                    <h4>По данному запросу ничего не найдено</h4>
                </div>

                <loader v-if="loadSearch" /> 

            </div>
        </div>



        <swiper ref="mySwiper" :options="swiperOptions" v-if="simpleNews.length">
            <!-- <swiper-slide>
                <div class="slide-box" :style="{'background-image': 'url(' + simpleNews[0].mainImage + ')'}">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-8 wow fadeInUp">
                                <p class="big-wh" data-swiper-parallax="-300">новости</p>
                                <h1 data-swiper-parallax="-200">{{simpleNews[0].name}}
                                </h1>
                                <p class="hidden-xs white-txt" v-html="simpleNews[0].descr"></p>
                            
                            </div>
                        </div>
                    </div>
                </div>
            </swiper-slide> -->
            <swiper-slide>
                <div class="slide-box" :style="{'background-image': 'url(' + mainImg + ')'}">
                        <video-background src="https://sflora.ru/wp-content/uploads/2022/12/flora_movie.mp4" class="bg_video">   
                         </video-background>
                            <div class="container">
                                <div class="row">
                                    <div class="col-lg-8 wow fadeInUp">
                                        <p class="big-wh" data-swiper-parallax="-300">Коллекционный питомник <br> декоративных растений </p>
                                        <h1 data-swiper-parallax="-200">
                                        «СЕВЕРНАЯ ФЛОРА»</h1>
                                        <p class="white-txt hidden-xs">
                                            Питомник "Северная флора" заложен в 2002 году для выращивания качественных здоровых растений с закрытой корневой системой, строго соответствующих сортовым признакам и пригодных для нашего климата. На участке площадью 1 га производится более 1000 сортов многолетних садовых цветов и 150 разновидностей лиственных кустарников.
                                        </p>
                                    
                                    </div>
                                </div>
                            </div>
                   
                </div>
            </swiper-slide>
            <swiper-slide v-for="(slide, index) in simpleNews" :key="index" v-show="index !== 0">
                <div class="slide-box" :style="{'background-image': 'url(' + slide.mainImage + ')'}">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-8 wow fadeInUp">
                                <p class="big-wh" data-swiper-parallax="-300">новости</p>
                                <h1 data-swiper-parallax="-200">{{slide.name}}
                                </h1>
                                <p class="hidden-xs white-txt" v-html="slide.descr"></p>
                            
                            </div>
                        </div>
                    </div>
                </div>
            </swiper-slide>
        </swiper>
        <div class="prevBtn newsPrev newsPrev2"></div>
        <div class="nextBtn newsNext newsNext2"></div>
        <div class="swiper-pagination"></div>
    </section>
</template>


<script>
import { mapGetters } from 'vuex'
import loader from '../components/ui/loader.vue'

    export default {
        components: {
            loader
        },
        computed: {
            ...mapGetters({
                loadSearch: "catalog/getLoadSearch",
                searchResults: "catalog/getSearchResults",
                simpleNews: "content/getSimpleNews"
            })
        },
        methods: {
            goSearch() {
                this.$store.dispatch("catalog/goSearch", this.search)
            },
            clearSearch() {
                this.search = ''
                this.$store.dispatch("catalog/clearSearch")
            },
            closeSearch() {
                this.openSearch = false
                this.search = ''
                this.$store.dispatch("catalog/clearSearch")
            }
        },
        data() {
            return {
                openSearch: false,
                mainImg: 'https://sflora.ru/wp-content/uploads/2022/05/vid.jpg',
                load: false,
                search: '',
                // slides: [{
                //         img: require('../assets/img/IMG_2516.jpg')
                //     },
                //     {
                //         img: require('../assets/img/IMG_2515.jpg')
                //     },
                //     {
                //         img: require('../assets/img/IMG_2517.jpg')
                //     },
                //     {
                //         img: require('../assets/img/IMG_2518.jpg')
                //     },
                //     {
                //         img: require('../assets/img/IMG_2514.jpg')
                //     },
                // ],
                swiperOptions: {
                    slidesPerView: 1,
                    spaceBetween: 0,
                    draggable: true,
                    parallax: true,
                    touchRatio: 1,
                    loop: true,
                    infinite: true,
                    speed: 600,
                    navigation: {
                        prevEl: '.newsPrev2',
                        nextEl: '.newsNext2'
                    },
                    pagination: {
						el: ".swiper-pagination",
                        clickable: true
					},
                },
            }
        }
    }
</script>

<style scoped>
.newsPrev{
    background-color: #9bbe3b;
    left: 20px;
}
.newsNext{
    background-color: #9bbe3b;
    right: 20px;
}
@media (max-width: 512px) {
    .newsPrev{
        left: 10px;
    }
    .newsNext{
            right: 10px;
    }
}
</style>

