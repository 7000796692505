<template>
    <section id="submit">
        <div class="blur-box"></div>
        <div class="container">
            <div class="row">
                <div class="col-lg-5">
                    <h2>есть вопросы? <br>
                        задавайте!</h2>
                    <form @submit.prevent="sendForm">
                        <div class="row">
                            <div class="col-lg-6">
                                <input type="text" placeholder="Ваше имя" v-model="cname" :class="{errorSubm : $v.cname.$dirty && !$v.cname.required}">
                            </div>
                            <div class="col-lg-6">
                                <input type="text" placeholder="Введите телефон" v-model="tel" v-mask="'+7 (###) ###-##-##'"
                                :class="{errorSubm : ($v.tel.$dirty && !$v.tel.required) || ($v.tel.$dirty && !$v.tel.minLength)}">
                            </div>
                            <div class="col-lg-6">
                                <input type="text" placeholder="Ваш e-mail" v-model="email"
                                :class="{errorSubm : ($v.email.$dirty && !$v.email.required) || ($v.email.$dirty && !$v.email.email)}">
                            </div>
                            <div class="col-lg-6">
                                <input type="text" placeholder="Тема" v-model="theme">
                            </div>
                            <div class="col-lg-12">
                                <textarea placeholder="Сообщение" v-model="messege"></textarea>
                                <button v-if="!loading" class="green-btn">Отправить</button>
                                <loader v-else />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
</template>


<script>
import {sameAs, required, email, minLength } from "vuelidate/lib/validators";
import axios from 'axios'
import loader from '../components/ui/loader.vue'

    export default{         
        components: {loader},
        data(){
            return{
                cname: '',
                tel: '',
                email: '',
                theme: '',
                messege: '',
                loading: false
            }
        },
        validations: {
            cname: {
                required
            },
            email:{
                required,
                email
            },
            tel: {
                required,
                minLength: minLength(18)
            },
        },
        methods: {
            sendForm(){
                if(this.$v.$invalid) {
                    this.$v.$touch();
                    return;
                }
                
                this.loading = true

                let emailBody = {
                    cname: this.cname,
                    tel: this.tel,
                    email: this.email,
                    theme: this.theme,
                    messege: this.messege
                }

                var form = new FormData();

                for (var field in emailBody){
                    form.append(field, emailBody[field]);
                };

                axios
            	.post('https://sflora.ru/wp-json/contact-form-7/v1/contact-forms/11725/feedback', form)
                .then(res => {
                    this.cname = '',
                    this.tel = '',
                    this.email = '',
                    this.theme = '',
                    this.messege = '',
                    this.loading = false
                    this.$v.$reset();
                    this.$toasted.show("Сообщение отправлено", { theme: "toasted-primary", position: "top-right", duration : 2000,});
                })
                .catch(err =>{
                    this.cname = '',
                    this.tel = '',
                    this.email = '',
                    this.theme = '',
                    this.messege = '',
                    this.loading = false
                    this.$v.$reset();
                    this.$toasted.show("Произошла ошибка", { theme: "toasted-primary", position: "top-right", duration : 2000, className: ['err-toast',]});
                })


            }
        }
    }
</script>


<style scoped>
.errorSubm{
    border-color: #E56036 !important;
}
</style>