<template>
    <div class="alphabet" v-if="categories.length">
        <button v-for="(btn, i) in getLetters" :key="i" :class="{'active-letter' : btn === activeFilterLetter}" 
        @click="setLetter(btn)">
            {{btn}}
        </button>
    </div>

</template>

<script>
import {mapGetters} from 'vuex'

export default {
    props: {
        categories: {
            required: true,
            type: Array
        }
    },
    computed: {
        ...mapGetters({
            activeFilterLetter: 'catalog/getActiveFilterLetter'
        }),
        getLetters(){
            let letArr = []
            this.categories.forEach(item =>{
                letArr.push(item.name[0])
            })
            
            return Array.from(new Set(letArr)) 
        }
    },
    data(){
        return{
            letters: [],
            activeCat: this.$route.params.cat
        }
    },
    methods: {
        setLetter(i){
            if(i === this.activeFilterLetter){
                i = ''
            }
            this.$store.dispatch('catalog/setActiveFilterLetter', i)
        }
    }
}
</script>