<template>
    <div class="filter">
        <button class="mob-sidebar-btn hidden-md hidden-lg hidden-xl" style="margin-top:0;" 
        :class="{'active' : showFilter}"
        @click="showFilter = !showFilter"> 
            <span class="mdi mdi-tune-variant"></span> Фильтр
            <sup v-if="countSelectedFilters">{{countSelectedFilters}}</sup>
        </button>
        <div class="row" :class="{'hidden-sm hidden-xs' : !showFilter}">
            <div class="col-lg-4">
                <label>Почва</label>
                <select v-model="filterValues.pochva">
                    <option value="" disabled>Выберите</option>
                    <option :value="i.slug" v-for="(i, index) in filtPar['pa_pochva']" :key="index">
                        {{i.name}}
                    </option>
                </select>
            </div>
            <div class="col-lg-4">
                <label>Время цветения</label>
                <select v-model="filterValues.vremyacveteniya">
                    <option value="" disabled>Выберите</option>
                    <option :value="i.slug" v-for="(i, index) in filtPar['pa_vremya-cveteniya']" :key="index">
                        {{i.name}}
                    </option>
                </select>
            </div>
            <div class="col-lg-4">
                <label>Отношение к свету</label>
                <select  v-model="filterValues.otnoshenieksvetu">
                    <option value="" disabled>Выберите</option>
                    <option :value="i.slug" v-for="(i, index) in filtPar['pa_otnoshenie-k-svetu']" :key="index">
                        {{i.name}}
                    </option>
                </select>
            </div>
            <div class="col-lg-4">
                <label>Ширина куста</label>
                <select v-model="filterValues.shirinakusta">
                    <option value="" disabled>Выберите</option>
                    <option :value="i.slug" v-for="(i, index) in filtPar['pa_shirina-kusta']" :key="index">
                        {{i.name}}
                    </option>
                </select>
            </div>
            <div class="col-lg-4">
                <label>Высота растения с цветоносом</label>
                <select v-model="filterValues.vysotascvetonosom">
                    <option value="" disabled>Выберите</option>
                    <option :value="i.slug" v-for="(i, index) in filtPar['pa_vysota-s-cvetonosom']" :key="index">
                        {{i.name}}
                    </option>
                </select>
            </div>
            <div class="col-lg-4">
                <button class="to-cart" @click="goSearch">Искать</button>
                <button class="clear-filters" @click="clearFilters">Очистить</button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import axios from 'axios'
import { API } from '../../config';

export default {
    data(){
        return {
            showFilter: false
        }
    },
    computed: {
        ...mapGetters({
            filterValues: 'catalog/getFilterValues',
            filtPar: 'catalog/getFilterParams'
        }),
        countSelectedFilters(){
            const arr = Object.values(this.filterValues)
            let num = 0
            arr.forEach((i) => {
                if(i !== ''){
                    num += 1
                }
            })
            return num < 1 ? null : num
        }
    },
    methods: {
        clearFilters(){
            this.$store.dispatch('catalog/clearFilters')
        },
        goSearch(){
            let newObj = {
                url: this.$route.params.sub,
                filters: {}
            }
            
            if(this.filterValues.pochva !== ''){
                newObj.filters['pa_pochva'] = this.filterValues.pochva
            }

            if(this.filterValues.vremyacveteniya !== ''){
                newObj.filters['pa_vremya-cveteniya'] = this.filterValues.vremyacveteniya
            }

            if(this.filterValues.otnoshenieksvetu !== ''){
                newObj.filters['pa_otnoshenie-k-svetu'] = this.filterValues.otnoshenieksvetu
            }

            if(this.filterValues.shirinakusta !== ''){
                newObj.filters['pa_shirina-kusta'] = this.filterValues.shirinakusta
            }

            if(this.filterValues.vysotascvetonosom !== ''){
                newObj.filters['pa_vysota-s-cvetonosom'] = this.filterValues.vysotascvetonosom
            }

            this.$store.dispatch('catalog/filterGoods', newObj).then(()=>{
                if(this.$route.name !== 'Filter'){
                    this.$router.replace({ path: '/filter' })
                }
            })
        }
    }
}
</script>

<style scoped>
.to-cart{
    margin-top: 36px;
    margin-right: 10px;
}
.clear-filters{
    font-size: 12px;
    background-color:#F2F2F2;
    border-radius: 50px;
    border: none;
    color: #333;
    font-weight: 700;
    padding: 10px 25px;
    transition: all .3s ease;
}
.clear-filters:hover{
    background-color:#ececec;
}
.mob-sidebar-btn{
    position: relative;
}
.mob-sidebar-btn.active{
    background-color: #c8ea6d;
}
sup{
    position: absolute;
    height: 18px;
    width: 18px;
    background-color: #E56036;
    top: -9px;
    right: -9px;
    border-radius: 20px;
    color: #fff;
    font-weight: 700;
    font-size: 9px;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>