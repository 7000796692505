<template>
  <div>
    <section id="cart">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="bredcrums">
              <span>Каталог</span>
              <span>Корзина</span>
            </div>
            <h2>Товаров в корзине: {{ cart.length }}</h2>
          </div>

          <div
            class="col-lg-12 text-center"
            v-if="!cart.length"
          >
            <!-- <img src="../assets/img/404img.gif" class="img404"> -->
            <h4>В вашей корзине пока что нет товаров</h4>
            <router-link
              tag="button"
              to="/product-category"
              class="to-cart"
            >
              ПЕРЕЙТИ К ПОКУПКАМ
              <span
                class="mdi mdi-chevron-right" 
                style="font-size:16px;"
              />
            </router-link>
          </div>

          <!-- <pre>{{cart}}</pre> -->

          <div
            class="col-lg-12"
            v-else
          >
            <div
              class="row cart-item"
              v-for="(item, index) in cart"
              :key="index"
            >
              <div class="col-lg-2">
                <img
                  :src="item.picture"
                  alt=""
                >
              </div>
              <div class="col-lg-3">
                <h4>Название</h4>
                <!-- <div class="new-prod" v-if="item.labels.includes('new')">Новинка</div>
                                <div class="pre-prod" v-if="item.labels.includes('preorder')">Предзаказ</div> -->
                <h4 style="font-size:16px;">
                  {{ item.name }}
                </h4>
              </div>
              <div class="col-lg-2 col-xs-6">
                <h4>Контейнер</h4>
                <p class="black-txt">
                  {{ item.container[getReg] }}
                </p>
              </div>
              <div class="col-lg-2 col-xs-6">
                <h4>Количество, шт.</h4>
                <div class="count-box">
                  <div
                    class="count-btn"
                    @click="changeAmountMinus(item, index)"
                  >
                    <img
                      src="../assets/img/min.svg"
                      alt=""
                    >
                  </div>
                  <span>{{ item.amount }}</span>
                  <div
                    class="count-btn"
                    @click="changeAmountPlus(item)"
                  >
                    <img
                      src="../assets/img/plus.svg"
                      alt=""
                    >
                  </div>
                </div>
              </div>
              <div class="col-lg-2 col-xs-6">
                <h4>Сумма</h4>
                <div class="price">
                  {{ item.price[getReg] * item.amount }} ₽
                </div>
              </div>
              <div class="col-lg-1 col-xs-6">
                <h4>Удалить</h4>
                <div
                  class="delete"
                  @click="deleteCart(index)"
                >
                  <span class="mdi mdi-delete-outline" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section
      id="payment"
      v-if="cart.length"
    >
      <div class="container">
        <div class="row">
          <div class="col-lg-4">
            <h2>Итог:</h2>
            <div class="pay-prices">
              <p class="grey-txt">
                Товар на сумму:
              </p>
              <h4>{{ getTotal }} ₽</h4>
            </div>
            <div
              class="pay-prices"
              v-if="salePerc !== '0'"
            >
              <p class="grey-txt">
                Размер скидки:
              </p>
              <h4>{{ salePerc }} %</h4>
            </div>
            <div
              class="pay-prices"
              v-if="certSaleAmount !== 0"
            >
              <p class="grey-txt">
                Размер скидки:
              </p>
              <h4>{{ certSaleAmount }} ₽</h4>
            </div>
            <div
              class="pay-prices"
              v-if="region === 'Регионы (рассылка на август)'"
            >
              <p class="grey-txt">
                Упаковка: {{ getGoodsLength - certAmount }}шт.
              </p>
              <h4>{{ 20 * (getGoodsLength - certAmount) }} ₽</h4>
            </div>
            <hr>

            <!-- итого -->
            <div class="pay-prices">
              <h2 style="margin-bottom: 0;">
                К оплате:
              </h2>
              <h3 v-if="region === 'Регионы (рассылка на август)'">
                {{ (getTotal + 20 * getGoodsLength - (certAmount * 20) - certSaleAmount).toLocaleString() }} ₽
              </h3>
              <h3 v-else>
                {{ (getTotal - certSaleAmount).toLocaleString() }} ₽
              </h3>
            </div>
            <!-- итого -->

            <br>
            <div
              class="alert alert-warning"
              role="alert"
              v-if="ablePromo"
            >
              <span class="mdi mdi-alert" /> Промокод и сертификат применяйте после окончательного формирования заказа в корзине!
            </div>
            <div
              class="pay-prices"
              v-if="ablePromo"
            >
              <input
                type="text"
                placeholder="Применить промокод"
                v-model="promocode"
              >
              <button
                class="to-cart"
                @click="checkPromo"
              >
                Применить
              </button>
            </div>



            <div v-if="checkTotal < minimalCart && !hasCertificate">
              <h4 style="color: #E56036;margin-bottom:0;font-size:16px;">
                Минимальная сумма заказа - {{ minimalCart }} ₽
              </h4>
              <h4>
                Для оформления заказа <br> вам осталось набрать на {{ minimalCart - checkTotal }} ₽
              </h4>
            </div>
                        
            <router-link
              tag="button"
              to="/product-category"
              class="to-cart"
            >
              ПЕРЕЙТИ К ПОКУПКАМ
              <span
                class="mdi mdi-chevron-right" 
                style="font-size:16px;"
              />
            </router-link>
          </div>
          <div class="col-lg-1 itog " />
          <div class="col-lg-1 " />
          <div
            class="col-lg-6"
            v-if="delivery.length"
          >
            <h2>Контактные данные:</h2>
            <div class="cont-pay">
              <div class="row">
                <div class="col-lg-4">
                  <label for="">Фамилия</label>
                  <input
                    type="text"
                    v-model="surname"
                    :class="{errorInp : $v.surname.$dirty && !$v.surname.required}"
                  >
                </div>
                <div class="col-lg-4">
                  <label for="">Имя</label>
                  <input
                    type="text"
                    v-model="name"
                    :class="{errorInp : $v.name.$dirty && !$v.name.required}"
                  >
                </div>
                <div class="col-lg-4">
                  <label for="">Отчество</label>
                  <input
                    type="text"
                    v-model="fatherName"
                    :class="{errorInp : $v.fatherName.$dirty && !$v.fatherName.required}"
                  >
                </div>
                                
            
                <div class="col-lg-4">
                  <label for="">Телефон</label>
                  <input
                    type="text"
                    placeholder="+7 ()"
                    v-model="tel"
                    v-mask="'+7 (###) ###-##-##'"
                    :class="{errorInp : ($v.tel.$dirty && !$v.tel.required) || ($v.tel.$dirty && !$v.tel.minLength)}"
                  >
                </div>
                <div class="col-lg-4">
                  <label for="">E-mail</label>
                  <input
                    type="text"
                    v-model="email"
                    :class="{errorInp : ($v.email.$dirty && !$v.email.required) || ($v.email.$dirty && !$v.email.email)}"
                    @input="email = email.replace(/\s/g, '')"
                  >
                </div>



                <!-- для регионов -->
                <div v-if="region === 'Регионы (рассылка на август)' ">
                  <div class="col-lg-4">
                    <label for="">Почтовый индекс</label>
                    <input
                      type="text"
                      v-model="postIndex"
                      :class="{errorInp : $v.postIndex.$dirty && !$v.postIndex.required}"
                    >
                  </div>

                  <div class="col-lg-6">
                    <label for="">Регион</label>
                    <input
                      type="text"
                      v-model="regionObl"
                      :class="{errorInp : $v.regionObl.$dirty && !$v.regionObl.required}"
                    >
                  </div>
                  <div class="col-lg-6">
                    <label for="">Населенный пункт</label>
                    <input
                      type="text"
                      v-model="punkt"
                      :class="{errorInp : $v.punkt.$dirty && !$v.punkt.required}"
                    >
                  </div>

                  <div class="col-lg-6">
                    <label for="">Улица</label>
                    <input
                      type="text"
                      v-model="street"
                      :class="{errorInp : $v.street.$dirty && !$v.street.required}"
                    >
                  </div>

                  <div class="col-lg-3">
                    <label for="">Дом</label>
                    <input
                      type="text"
                      v-model="house"
                    >
                  </div>
                  <div class="col-lg-3">
                    <label for="">Квартира</label>
                    <input
                      type="text"
                      v-model="flat"
                    >
                  </div>




    


                  <div class="col-lg-12">
                    <label for="">Выберите тип доставки</label><br>
                    <div class="deliv-btns">
                      <div
                        class="deliv-btn" 
                        :class="{ 'active-delivery' : activeDeliv == index }"
                        v-for="(del, index) in delivery" 
                        :key="index"
                        @click="changeDelivery(index)"
                        v-show="del.active === 'yes'"
                      >
                        {{ del.title }}
                      </div>
                    </div>
                  </div>
                </div>
                <!-- для регионов -->

                <!-- Для СПБ и ЛО -->

                <div v-else>
                  <div class="col-lg-12">
                    <label for="">Выберите пункт самовывоза</label><br>
                    <div class="deliv-btns">
                      <div
                        class="deliv-btn" 
                        :class="{ 'active-delivery' : samovivoz == index }"
                        v-for="(pnkt, index) in punkts" 
                        :key="index"
                        @click="changeSamovivoz(index)"
                      >
                        {{ pnkt }}
                      </div>
                    </div>
                  </div>
                </div>



                <div class="col-lg-12">
                  <label for="">Комментарий к заказу</label>
                  <input
                    type="text"
                    v-model="comment"
                  >
                  <hr>
                </div>

                <!-- Для СПБ и ЛО -->
                                

                <div class="col-lg-12" />


                                
                <div v-if="checkTotal >= minimalCart || hasCertificate">
                  <div class="col-lg-4">
                    <button
                      style="width:100%;"
                      @click="createOrder" 
                      class="to-cart"
                      v-if="!loading"
                    >
                      Оформить заказ
                    </button>
                    <loader v-else />
                  </div>
                  <div class="col-lg-8">
                    <div
                      class="form-check"
                      :class="{invalidCheck: $v.aceptedForm.$invalid}"
                    >
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="exampleCheck1"
                        v-model="aceptedForm"
                        @change="$v.aceptedForm.$touch()"
                      >
                      <label
                        class="form-check-label"
                        for="exampleCheck1"
                      >Я согласен с обработкой персональных данных» и я ознакомлен с правилами пересылки растений</label>
                    </div>
                  </div>
                </div>
              </div>    
            </div>
          </div>
        </div>
      </div>
    </section>



    <div
      class="pay-pop"
      v-if="tinkoff_link !== '' "
    >
      <div
        class="cross"
        @click="validatePay()"
      >
        ×
      </div>
      <iframe
        :src="tinkoff_link"
        frameborder="0"
      />
    </div>
  </div>
</template>

<script>
import {sameAs, required, email, minLength } from "vuelidate/lib/validators";
import axios from 'axios'
import {mapGetters} from 'vuex'
import { API } from '../config';
import loader from '../components/ui/loader.vue'
const sha256 = require("js-sha256").sha256
export default{
    components: {loader},
    data(){ 
        return{
            minimalCart: 2500,
            tinkoff_link: '',
            name: '',
            surname: '',
            fatherName: '',
            tel: '',
            email: '',
            postIndex: '',
            comment: '',
            regionObl: '',
            punkt: '',
            street: '',
            house: '',
            flat: '',
            loading: false,
            paymentId: '',
            promocode: '',
            activeDeliv: 0,
            samovivoz: 0,
            // punkts: ['Питомник'],
            punkts: ['Питомник', 'ул. Промышленная, д. 6 (м. Нарвская)', 'Пулковское шоссе, д. 53 к 2', 'ул. Привокзальная, д.3 (м. Девяткино)', 'ул.  Сытнинская, дом 5 лит. А (м. Горьковская)'],
            aceptedForm: false
        }
    },
    validations: {
		name: {
			required
		},
		email:{
			required,
			email
		},
		tel: {
			required,
			minLength: minLength(18)
		},
        surname: {
            required
        },
        fatherName: {
            required
        },
        postIndex: {
            required
        },
        regionObl: {
            required
        },
        punkt: {
            required
        },
        street: {
             required
        },
        aceptedForm: {
            sameAs: sameAs( () => true )
        }
	},
    computed: {
        ...mapGetters({
            cart: "cart/getCart",
            region: "catalog/getRegion",
            ablePromo: "cart/getAblePromo",
            salePerc: "cart/getSalePerc",
            delivery: "cart/getDelivery",
            promoCodeName: "cart/getPromoName",
            certSaleAmount: "cart/getCertSaleAmount"
        }),
        getMinimalCart(){
          return this.samovivoz === 0 ? 1 : 2500 
        },
        getGoodsLength(){
            let goodsAll = 0
            this.cart.forEach(item =>{
                goodsAll += item.amount
            })
            return goodsAll
        },
        getReg(){
            if(this.region === 'Санкт-Петербург и ЛО (самовывоз на весну)'){
                return 'sl'
            }else{
                return 'rg'
            }
        },
        getTotal(){
            
            let total = 0
            this.cart.forEach(item =>{
                // let itemPrice = item.price * item.amount
                if(item.slug.includes('sertifikat')){
                    total += item.price[this.getReg] * item.amount
                }else{
                    total += item.price[this.getReg] * item.amount
                }
            })
            return total
        },
        checkTotal(){
            let total = 0
            if(this.region === 'Регионы (рассылка на август)'){
                total = (this.getTotal + 20 * this.getGoodsLength)
            }else{
                total = this.getTotal
            }
            return total
        },
        hasCertificate(){
            let hasCertificate = this.cart.find((item) => {
                return item.slug.includes('sertifikat')
            })
            if(hasCertificate){
                return true
            }else{
                return false
            }
        },
        certAmount(){
            let hasCertificate = this.cart.filter((item) => {
                return item.slug.includes('sertifikat')
            })
            return hasCertificate.length
        }
    },
    // watch: {
    //     cart(){
    //         if(!this.cart.length){
    //             this.$store.dispatch("cart/clearCart")
    //         }
    //     }
    // },
    methods: {
        changeSamovivoz(index){
            this.samovivoz = index
        },
        changeDelivery(index){
            this.activeDeliv = index
        },
        checkPromo(){
            let promo = {
                code: this.promocode
            }
            axios
            .post(`${API}info/promo`, promo)
            .then(res =>{
                if(res.data.amount !== '0'){
                    if(res.data.discount_type === 'fixed_cart'){
                        if(res.data.usage_count >= res.data.usage_limit){
                            this.$toasted.show(`Сертификат недействителен` , { theme: "toasted-primary", position: "top-right", duration : 2000, className: ['err-toast',]});
                            return
                        }
                        if(res.data.amount > 2500){
                            this.minimalCart = parseInt(res.data.amount) + 1
                        }
                        this.$toasted.show(`Применен сертификат на скидку ${res.data.amount} ₽` , { theme: "toasted-primary", position: "top-right", duration : 2000,});
                        let catalog = {
                            amount: res.data.amount,
                            region: this.region,
                            promoCodeName: this.promocode
                        }
                        this.$store.dispatch("cart/applyCert", catalog)
                    }else{
                        this.$toasted.show(`Применен промокод на скидку ${res.data.amount}%` , { theme: "toasted-primary", position: "top-right", duration : 2000,});
                        let catalog = {
                            percent: res.data.amount,
                            region: this.region,
                            promoCodeName: this.promocode
                        }
                        this.$store.dispatch("cart/applyPromo", catalog)
                    }
                }else{
                    this.$toasted.show(`Промокод недействителен` , { theme: "toasted-primary", position: "top-right", duration : 2000, className: ['err-toast',]});
                }
            })
        },
        
        createOrder(){
            let deliveryType
            let samovivozPlace
            if(this.region === 'Регионы (рассылка на август)'){
                this.samovivoz = false
                samovivozPlace = false
                deliveryType = this.delivery[this.activeDeliv].title
            }else{
                this.postIndex = false
                this.regionObl = false
                this.punkt = false
                this.street = false
                this.house = false
                this.flat = false
                this.activeDeliv = false
                deliveryType = false
                samovivozPlace = this.punkts[this.samovivoz]
            }
            if(this.$v.$invalid) {
					this.$v.$touch();
					return;
			}
            this.loading = true
            let order = {
                userInfo: {
                    surname: this.surname,
                    cname: this.name,
                    fatherName: this.fatherName,
                    tel: this.tel,
                    email: this.email,
                    postIndex: this.postIndex,
                    regionObl: this.regionObl,
                    punkt: this.punkt,
                    street: this.street,
                    house: this.house,
                    flat:  this.flat,
                    comment: this.comment,
                    deliveryType: deliveryType,
                    samovivozPlace: samovivozPlace,
                    region: this.region,
                },
                cart: this.cart,
                promoCodeName: this.promoCodeName
            }
            axios
            .post(`${API}create/user/sale`, order)
            .then(res =>{
                // console.log(res.data.order.id)
                this.goPay(res.data.order.id)
                // this.$toasted.show("Ваш заказ успешно оформлен", { theme: "toasted-primary", position: "top-right", duration : 5000,});
                // this.$store.dispatch("cart/clearCart")
                // this.$store.dispatch("catalog/loadAllGoods")
                // this.$store.dispatch("catalog/loadPopular")
                // this.$store.dispatch("catalog/loadNew")
                // this.$store.dispatch("catalog/loadMerch")
                // this.$store.dispatch("cart/clearSert")
                // this.$router.replace("/success-order");
            })
            .catch((error) => {
                this.loading = false
                this.$store.dispatch("catalog/loadAllGoods")
                this.$store.dispatch("catalog/loadPopular")
                this.$store.dispatch("catalog/loadNew")
                this.$store.dispatch("catalog/loadMerch")
                this.$store.dispatch("cart/rebuildCart", error.response.data.product_id)
                this.$toasted.show("Ошибка оплаты! Один или несколько товаров распроданы", { theme: "toasted-primary", position: "top-right", duration : 5000, className: ['err-toast',]});
                // this.$toasted.show("Ошибка заказа! попробуйте снова", { theme: "toasted-primary", position: "top-right", duration : 5000, className: ['err-toast',]});
            })
        },
        deleteCart(index){
             this.$store.dispatch('cart/deleteCart', index)
        },
        changeAmountPlus(item){
            if(this.region === 'Санкт-Петербург и ЛО (самовывоз на весну)'){
                if(item.amount == item.quantity.sl){
                    return
                }
                this.$store.dispatch('cart/changeAmountPlus', item)
            }else{
                if(item.amount == item.quantity.rg){
                    return
                }
                this.$store.dispatch('cart/changeAmountPlus', item)
            }
            
        },
        changeAmountMinus(item, index){
            let param = {
                item,
                index
            }
            this.$store.dispatch('cart/changeAmountMinus', param)
        },
        goPay(param){
            let cartJson = []
            this.cart.forEach(item =>{
                let jsonItem = {
                    Name: item.name,
                    Price: this.certSaleAmount !== 0 ? 0 : (item.price[this.getReg] * 100),
                    Quantity: (item.amount),
                    Amount: this.certSaleAmount !== 0 ? 0 :  (item.price[[this.getReg]] * 100 * item.amount),
                    PaymentMethod: "full_prepayment",
                    PaymentObject: "commodity",
                    Tax: "vat10",
                    Ean13: "0123456789"
                }
                cartJson.push(jsonItem)
            })
            if(this.certSaleAmount !== 0 && this.region !== 'Регионы (рассылка на август)'){
                cartJson[0].Price = (this.getTotal - this.certSaleAmount) * 100
                cartJson[0].Amount = (this.getTotal - this.certSaleAmount) * 100
            }
            if(this.certSaleAmount !== 0 && this.region === 'Регионы (рассылка на август)'){
                cartJson[0].Price = (this.getTotal + 20 * this.getGoodsLength - (this.certAmount * 20) - this.certSaleAmount) * 100
                cartJson[0].Amount = (this.getTotal + 20 * this.getGoodsLength - (this.certAmount * 20) - this.certSaleAmount) * 100
            }
            
            if(this.region === 'Регионы (рассылка на август)' && this.certSaleAmount === 0){
                let packageBox = {
                    Name: "Упаковка",
                    Price: 2000,
                    Quantity: this.getGoodsLength - this.certAmount,
                    Amount: 2000 * (this.getGoodsLength - this.certAmount),
                    PaymentMethod: "full_prepayment",
                    PaymentObject: "commodity",
                    Tax: "vat10",
                    Ean13: "0123456789"
                }
                cartJson.push(packageBox)
            }
            // подсчет тотала для тинкофф
            let tinkoffAmount = 0
            if(this.region === 'Регионы (рассылка на август)' &&  this.certSaleAmount === 0){
                tinkoffAmount = (this.getTotal + (20 * this.getGoodsLength) - (this.certAmount * 20)) * 100
            }else if(this.region === 'Регионы (рассылка на август)' &&  this.certSaleAmount !== 0){
                tinkoffAmount = (this.getTotal + 20 * this.getGoodsLength - (this.certAmount * 20) - this.certSaleAmount) * 100
            }else{
                tinkoffAmount = (this.getTotal - this.certSaleAmount) * 100
            }

             // подсчет тотала для тинкофф
            let requset = {
                TerminalKey: "1624958215790",
                Amount: (tinkoffAmount.toString()), 
                OrderId: param,
                Description: "Оплата заказа " + param + " Северная флора",
                NotificationURL: 'https://sflora.ru/wp-content/plugins/tinkoff-woocommerce/tinkoff/success.php',
                DATA: {
                    Phone: this.tel,
                    Email: this.email
                },
                Receipt: {
                    Email: this.email,
                    Phone: this.tel,
                    EmailCompany: "pitomnik@sflora.ru",
                    Taxation: "osn",
                    Items: cartJson
                },
                Token: sha256(tinkoffAmount.toString() +  "Оплата заказа " + param + " Северная флора" + 'https://sflora.ru/wp-content/plugins/tinkoff-woocommerce/tinkoff/success.php' + param + "tbr5r0ocap5dczy4" + "1624958215790")
            }
            axios.post('https://securepay.tinkoff.ru/v2/Init', JSON.parse(JSON.stringify(requset)))
            .then(res =>{
                this.tinkoff_link = res.data.PaymentURL;
                this.loading = false
                console.log(res.data)
                this.paymentId = res.data.PaymentId
                //запомнить payment id - 876456656
            })
            .catch(function (error) {
                console.log(error);
            });
         
            return false;
            
        },
        validatePay(){
            this.tinkoff_link = ''
              
            let requset = {
                "TerminalKey": "1624958215790",
                "PaymentId": this.paymentId,
                "Token": sha256("tbr5r0ocap5dczy4" + this.paymentId + "1624958215790")
            }
            axios.post('https://securepay.tinkoff.ru/v2/GetState',requset)
            .then((response) => {
                console.log("GetState", response)
                  if (response.data.Status === "CONFIRMED" ){
                        this.$toasted.show("Ваш заказ успешно оплачен", { theme: "toasted-primary", position: "top-right", duration : 2000,});
                        this.$store.dispatch("cart/clearCart")
                        this.$store.dispatch("catalog/loadAllGoods")
                        this.$store.dispatch("catalog/loadPopular")
                        this.$store.dispatch("catalog/loadNew")
                        this.$store.dispatch("catalog/loadMerch")
                        this.$store.dispatch("cart/clearSert")
                        // обнулить сертификат!!!!
                  }else{
                      this.$toasted.show("Ошибка оплаты", { theme: "toasted-primary", position: "top-right", duration : 2000, className: ['err-toast',]});
                  }
            })
            .catch(function (error) {
                console.log(error);
            });
        }
    },
    mounted(){
      // ОЧИСТКА СТАРОЙ КОРЗИНЫ
      localStorage.removeItem("cart");
    }
}
</script>


<style scoped>
input{
    color: #000;
}
.errorInp{
    border: 1px red solid;
}
</style>