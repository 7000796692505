import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import VueKinesis from "vue-kinesis";
import * as basicScroll from 'basicscroll'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import JwPagination from 'jw-vue-pagination';
import VueScrollTo from 'vue-scrollto'
import VueLazyload from 'vue-lazyload'
import Vuelidate from 'vuelidate'
import VueTheMask from 'vue-the-mask'
import Toasted from 'vue-toasted'
import YmapPlugin from 'vue-yandex-maps'
import { CoolSelectPlugin } from 'vue-cool-select'
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import 'vue-cool-select/dist/themes/material-design.css'
import VideoBackground from 'vue-responsive-video-background-player'
const ImageZoom = require('vue-image-zoomer').default;

Vue.component('jw-pagination', JwPagination);
Vue.config.productionTip = false

import 'swiper/css/swiper.css'

const user = JSON.parse(localStorage.getItem("user"))
store.dispatch("auth/VALIDATE", user);

const region = JSON.parse(localStorage.getItem("region"))
store.dispatch("catalog/VALIDATE_REG", region);
// localStorage.removeItem("region")

// ОЧИСТКА СТАРОЙ КОРЗИНЫ
localStorage.removeItem("cart");

const cart = JSON.parse(localStorage.getItem("cart_new"))
store.dispatch("cart/VALIDATE_CART", cart);


const hiddenPromo = JSON.parse(localStorage.getItem("hiddenPromo"))
store.dispatch("cart/VALIDATE_HID_PROMO", hiddenPromo);


const settings = {
  apiKey: '8c4059db-3b8d-4535-a15e-569ee80fc827',
  lang: 'ru_RU',
  coordorder: 'latlong',
  version: '2.1'
}
Vue.use(YmapPlugin, settings)
Vue.use(CoolSelectPlugin)
Vue.use(CoolLightBox)
Vue.component('video-background', VideoBackground)
Vue.use(Toasted)
Vue.use(VueKinesis)
Vue.use(VueAwesomeSwiper)
Vue.use(VueScrollTo)
Vue.use(Vuelidate)
Vue.use(VueTheMask)
Vue.use(VueLazyload, {
  preLoad: 1.3,
  error: require('./assets/img/preloadimg.svg'),
  loading: require('./assets/img/preloadimg.svg'),
  attempt: 1,
  listenEvents: [ 'scroll' ]
})
Vue.component('image-zoom', ImageZoom);


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
