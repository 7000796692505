<template>
    <section id="merch">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="bredcrums">
                        <span><router-link tag="a" to="/product-category">Каталог</router-link></span>
                        <span>Печатная продукци</span>
                    </div>
                    <h2>Печатная продукция</h2>
                </div>


                <div class="col-lg-4  col-sm-6" v-for="(item, index) in merchItems.items" :key="index">
                    <GoodCard :goodItem="item" />
                </div>




            </div>
        </div>
    </section>
</template>

<script>
import { mapGetters } from 'vuex'
import GoodCard from '../components/GoodCard.vue'

export default{
    components: {
        GoodCard
    },
    computed: {
        ...mapGetters({
            merchItems: "catalog/getMerch"
        })
    }
}
</script>
