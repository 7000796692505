<template>
    <div v-if="comments.length">
        <div class="single-comment" v-for="(item, index) in comments" :key="index">
            <img :src="item.user_avatar" alt="">
            <div>
                <h4>{{item.user_name}}</h4>
                <p class="black-txt">{{item.comment}}</p>
                <p class="small-black">{{item.date}}</p>
            </div>
        </div>
    </div>
    <div v-else>
        <div class="single-comment">
            <h4>Комментарии пока что отсутствуют</h4>
        </div>
    </div>
</template>

<script>

export default {
    props: {
        comments: {
            type: Array,
            required: true
        }
    }
}
</script>