import axios from 'axios'
import { API } from '../config';
import router from '../router'

const auth = {
	namespaced: true,
	state: {
        user: null,
		token: null,
        loginError: false,
        wishlist: []
  	},
	mutations: {
		SET_USER(state, user){
			state.user = user
		},
		SET_TOKEN(state, token) {
            state.token = token;
        },
        CHANGE_AVATAR(state, avatar){
            state.user.avatar = avatar
            localStorage.setItem("user", JSON.stringify(state.user));
        },
        SET_LOGERR(state, payload){
            state.loginError = payload
        },
        UPDATE_USER(state, user){
            state.user = user
            state.user.token = state.token
            localStorage.setItem("user", JSON.stringify(state.user));
        },
        SET_WISHLIST(state, wishlist){
            state.wishlist = wishlist
        }
	},
	actions: {
        async AUTH_REQUEST({ commit, dispatch }, payload) {
            try {
                const { data } = await axios.post(`https://sflora.ru/wp-json/jwt-auth/v1/token`, payload)
                commit("SET_LOGERR", false)
                return dispatch('VALIDATE', data)
            }
            catch (err) {
                commit("SET_LOGERR", true)
            }
        },
        async VALIDATE({ commit, state, dispatch }, user) {
            if (user) {
                commit("SET_TOKEN", user.token);
                commit("SET_USER", user);
            }
            if (!state.user) {
                return
            }
            try {
                const response = await axios({
                    url: `https://sflora.ru/wp-json/jwt-auth/v1/token/validate`,
                    method: 'post',
                    headers: {
                        'Authorization': `Bearer ${user.token}`
                    }
                });

                localStorage.setItem("user", JSON.stringify(user));
                commit("SET_TOKEN", user.token);
                commit("SET_USER", user);
                dispatch("getWishlist", user.id)
            }
            catch (err) {
                localStorage.removeItem("user");
                commit("SET_TOKEN", null);
                commit("SET_USER", null);
            }
        },
        async SIGN_OUT({ commit }) {
            localStorage.removeItem("user");
            commit("SET_TOKEN", null);
            commit("SET_USER", null);
        },
        async SIGN_UP({commit}, payload){
            
            try {
                const { data } = await axios.post(`https://sflora.ru/wp-json/sf/v1/add/user`, payload)
                
                if(data.status === 'Вы уже зарегистрированы'){
                	return data.status
                }
                	
            }
            catch (err) {
            	alert('Произошла ошибка')
            }
        },
        updateUser({commit}, user){
            localStorage.removeItem("user");
            commit("UPDATE_USER", user)
        },
        getWishlist({commit}, id){
            axios.get(`https://sflora.ru/wp-json/yith/wishlistsf/v2/wishlists?user_id=${id}`)
            .then(res =>{
                commit("SET_WISHLIST", res.data)
            })
        },
        setLike({commit, dispatch}, params){
            axios.post(`https://sflora.ru/wp-json/yith/wishlistsf/v2/wishlists/add?user_id=${params.user}&product_id=${params.good}`)
            .then(res =>{
                commit("SET_WISHLIST", res.data)
            })
            .catch(err =>{
                if(err.response.data.status == 422){
                    dispatch("deleteLike", params)
                }
            })
        },
        deleteLike({commit}, params){
            axios.delete(`https://sflora.ru/wp-json/yith/wishlistsf/v2/wishlists/remove?user_id=${params.user}&product_id=${params.good}`)
            .then(res =>{
                commit("SET_WISHLIST", res.data)
            })
        },
        async REMEMBER_PASS({commit}, email){
            
            try {
                const { data } = await axios.get(`https://sflora.ru/wp-json/sf/v1/forgot/user?email=${email}`)
                console.log(data)
                return data	
            }
            catch (err) {
            	// return err.response.data
            }
        }
	},
	getters: {
        getLikes(state){
            return state.wishlist
        },
		getAuthenticated(state){
            return state.user
        },
        getLoginErr(state){
            return state.loginError
        }
	}
}

export default auth


