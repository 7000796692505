<template>
    <div>
        <section class="hidden-lg hidden-md">
                <div class="col-lg-12">
                    <button class="mob-sidebar-btn" @click="mobileSidebar = true"> <span class="mdi mdi-menu"></span> Меню каталога</button>
                </div>
        </section>

        <div class="sidebar-wrapper hidden-lg hidden-md" v-if="mobileSidebar" @click="mobileSidebar = false">
            <ul v-if="categories.length" @click.stop>
                <li v-for="(cat, index) in categories" :key="index">
                    <div class="cat-title" :class="{'act-cat' : index == subActive}" @click="openCat(index)">
                        <router-link tag="h5" :to="`/product-category/${cat.slug}`" @click.stop>{{cat.name}}</router-link>
                        <img src="@/assets/img/arrd.svg" v-if="cat.child.length">
                    </div>
                    <ul class="sub-links" v-if="index == subActive && cat.child.length">
                        <router-link tag="li"  
                        class="grey-link" 
                        v-for="(sub, id) in cat.child" :key="id"
                        :to="`/product-category/${cat.slug}/${sub.slug}`" @click.native="mobileSidebar = false">
                          <span v-if="sub.count != 0">  {{sub.name}}</span>
                          <!--  ({{sub.count}}) -->
                        </router-link>
                    </ul>
                </li>
                <!-- <li>
                    <div class="cat-title">
                        <router-link tag="h5" to="/new-products">
                        <span class="mdi mdi-star"></span>Новинки</router-link>
                    </div>
                </li> -->
                <li>
                    <div class="cat-title">
                        <router-link tag="h5" to="/delivery">
                            <span class="mdi mdi-map-marker"></span>
                            Доставка
                        </router-link>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        categories: {
            required: true,
            type: Array
        }
    },
    data(){
        return{
            subActive: null,
            mobileSidebar: false
        }
    },
    watch:{
        categories(){
            for(let i = 0; i < this.categories.length; i++){
                if(this.categories[i].slug === this.$route.params.cat){
                    this.subActive = i
                }
            }
        }
    },
    methods: {
        openCat(index){
            if(index == this.subActive){
                this.subActive = null
            }else{
                this.subActive = index
            }
        }
    }
}
</script>