<template>
    <section id="enter">
        <div class="container">
            <div class="row">
                <div class="col-lg-4 col-lg-offset-4">
                    <form @submit.prevent="submit" class="text-center">
                        <h3>Забыли пароль</h3>
                        <br><br>
                        <label for="">Введите ваш e-mail</label>
                        <input type="text" placeholder="E-mail" v-model="email" :class="{errorSubm : ($v.email.$dirty && !$v.email.required) || ($v.email.$dirty && !$v.email.email)}">
                        <button v-if="!loading" class="to-cart" type="submit">Восстановить</button>
                        <loader v-else />
                        <p class="black-txt" v-if="result">
                            <strong>{{result}}</strong>
                        </p>
                        <br><br>
                        <p class="small-txt">
                            Еще нет учетной записи? <br>
                            <router-link tag="a" to="/signup">Зарегистрироваться</router-link>
                        </p>
                    </form>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import {sameAs, required, email, minLength } from "vuelidate/lib/validators";
import loader from '../components/ui/loader.vue'

export default {
    components: {loader},
    validations: {
        email:{
            required,
            email
        }
    },
    methods: {
        submit(){
            if(this.$v.$invalid) {
                 this.$v.$touch();
                return;
            }
            this.loading = true

            this.$store.dispatch('auth/REMEMBER_PASS', this.email).then((res)=>{

                this.loading = false
                this.result = res
                this.email = ''
                this.$v.$reset();
            })

        }
    },
    data(){
        return{
            email: '',
            loading: false,
            result:  null
        }
    }
}
</script>

<style scoped>
.small-txt a{
    color: #9BBE3B;
}
.errorSubm{
    border-color: #E56036 !important;
}
</style>