<template>
    <section id="categories">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <h2>Категории товаров</h2>
                </div>
                <div class="col-lg-4" v-for="(cat, index) in categories" :key="index">
                    <router-link tag="div" :to="cat.link" class="cat-box">
                        <img :src="cat.img" alt="">
                        <h3>{{cat.title}}</h3>
                    </router-link>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    data(){
        return{
            categories: [
                {
                    img: require('../assets/img/icons/ic1.svg'),
                    title: 'Кустарники',
                    link: '/product-category/kustarniki'
                },
                {
                    img: require('../assets/img/icons/ic3.svg'),
                    title: 'Многолетники',
                    link: '/product-category/mnogoletniki'
                },
                {
                    img: require('../assets/img/icons/ic2.svg'),
                    title: 'Флоксы летнего цветения',
                    link: '/product-category/floksy-letnego-czveteniya'
                },
                {
                    img: require('../assets/img/icons/ic4.svg'),
                    title: 'Флоксы весеннего цветения',
                    link: '/product-category/floksy-vesennego-czveteniya'
                },
                {
                    img: require('../assets/img/icons/ic7.svg'),
                    title: 'Злаки',
                    link: '/product-category/zlaki'
                },
                // {
                //     img: require('../assets/img/icons/ic8.svg'),
                //     title: 'Земляника',
                //     link: '/product-category/zemlyanika-2'
                // },
                {
                    img: require('../assets/img/icons/ic6.svg'),
                    title: 'Новинки',
                    link: '/new-products'
                },
                {
                    img: require('../assets/img/icons/ic9.svg'),
                    title: 'Сертификат',
                    link: '/certificate'
                },
                {
                    img: require('../assets/img/icons/ic5.svg'),
                    title: 'Печатная продукция',
                    link: '/pechatnaya-produkcziya'
                },
                {
                    img: require('../assets/img/icons/ic10.svg'),
                    title: 'Доставка',
                    link: '/delivery'
                },
            ]
        }
    }
}
</script>