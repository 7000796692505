<template>
    <table>
        <tr>
            <td>Отношение к свету</td>
            <td class="detail-url text-right" @click="redirectDetails(spec.otnoshenieksvetu, 0)">{{spec.otnoshenieksvetu}}</td>
        </tr>
        <tr>
            <td>Почва</td>
            <td class="detail-url text-right" @click="redirectDetails(spec.pochva, 2)">{{spec.pochva}}</td>
        </tr>
        <tr>
            <td>Ширина куста (см)</td>
            <td class="detail-url text-right" @click="redirectDetails(spec.shirinakusta, 3)">{{spec.shirinakusta}}</td>
        </tr>
        <tr>
            <td>Высота с цветоносом (см)</td>
            <td class="detail-url text-right" @click="redirectDetails(spec.vysotascvetonosom, 8)">{{spec.vysotascvetonosom}}</td>
        </tr>
        <tr>
            <td>Время цветения</td>
            <td class="detail-url text-right" @click="redirectDetails(spec.vremyacveteniya, 5)">{{spec.vremyacveteniya}}</td>
        </tr>
        <tr v-if="spec.czvet !== '' ">
            <td>Цвет</td>
            <td class="detail-url text-right" @click="redirectDetails(spec.czvet, 9)">
                {{spec.czvet}}</td>
        </tr>
    </table>
</template>

<script>
import axios from 'axios'

export default {
    props: {
        spec:{
            type: Object,
            required: true
        },
        catSlug: {
            type: String,
            required: true
        }
    },
    methods:{
        redirectDetails(param, num){
            let deatails= {
                objParam: {
                    [Object.keys(this.spec)[num]]: param
                },
                urlParam: this.catSlug
            }
            this.$store.dispatch('catalog/loadDetailGoods', deatails)
            this.$router.push('/details-products')

        }
    }
}
</script>

<style scoped>
table{
    width: 100%;
    table-layout: auto;
}
td{
    padding: 10px 0;
    border-bottom: 1px #f2f2f2 solid;
}
table tr td:first-child{
    font-size: 14px;
    color: #646464;
}
table tr td:last-child{
    font-size: 14px;
    color: #000;
    font-weight: 500;
}
.detail-url{
    cursor: pointer;
    text-decoration: underline;
}
.detail-url:hover{
    text-decoration: none;
    color: #9BBE3B;
}
</style>