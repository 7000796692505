<template>
  <header :class="{'white-header' : this.$route.name !== 'Home'}">
    <MobileNav
      v-if="mobileMenu"
      @hideMenu="mobileMenu = false"
    />

    <div class="container">
      <div class="shapka">
        <router-link
          tag="a"
          to="/"
        >
          <img
            src="@/assets/img/logo.svg"
            class="logo"
            v-if="this.$route.name === 'Home'"
          >
          <img
            src="@/assets/img/dlogo.svg"
            class="logo"
            v-else
          >
        </router-link>
                
        <div class="region">
          <span class="mdi mdi-map-marker" />
          {{ region.replace(/ *\([^)]*\) */g, "") }}
          <div class="region-change">
            <ul>
              <li
                v-for="(item, index) in regions" 
                :key="index" 
                :class="{'active-region' : item.txt === region }"
                @click="changeRegion(item.txt)"
              >
                {{ item.txt }}
              </li>
            </ul>
          </div>
        </div>

        <cool-select
          class="hidden-xs"
          :items="searchRes"
          :placeholder="'Начните поиск'"
          @search="onSearch"
          :loading="loading"
          :search-text.sync="searchInp"
          disable-filtering-by-searc
          item-text="nameR"
          :disable-first-item-select-on-enter="true"
          @keyup.enter="goToResults()"
          ref="coolSel"
        >
          <!-- slot for each item in the menu -->
          <template
            slot="item"
            slot-scope="{ item: link }"
          >
            <router-link
              tag="div"
              :to="`/product/${link.slug}`"
              style="display: flex; align-items: center;"
            >
              <img
                :src="link.image"
                class="search-flag"
                v-if="link.image"
              >
              <div v-html="link.name" />
            </router-link>
          </template>

          <div
            slot="before-items"
            class="slot"
            v-if="searchInp && searchInp !== '' "
          >
            <router-link
              tag="div"
              :to="`/search-results=${searchInp}`"
              class="default-search"
            >
              Искать по запросу: {{ searchInp }} <span class="mdi mdi-arrow-right" />
            </router-link>
          </div>

          <template slot="input-end">
            <span
              class="mdi mdi-close"
              @click="clearSearch"
            />
          </template>

          <template slot="no-data">
            Нет результатов
          </template>
        </cool-select>



        <div class="header-btns">
          <div
            class="burger hidden-lg hidden-md"
            @click="mobileMenu = true"
          >
            <span />
            <span />
          </div>
          <router-link
            tag="div"
            to="/cart"
            class="cart-btn hidden-xs hidden-sm"
            style="margin-right:15px;"
          >
            <span class="mdi mdi-cart" />
            <div class="cart-count">
              {{ cart.length }}
            </div>
          </router-link>

          <router-link
            v-if="!user"
            tag="div"
            to="/enter"
            class="lk-btn hidden-xs hidden-sm"
            style="margin-right:0px;"
          >
            Вход
          </router-link>

          <router-link
            v-else
            tag="div"
            to="/profile"
            class="lk-btn hidden-xs hidden-sm"
            style="margin-right:0px;"
          >
            <span class="mdi mdi-account" />
            Личный кабинет
          </router-link>
                    
          <!-- <button class="green-btn">+7 (921) 332-13-80</button> -->
          <div
            class="hidden-xs hidden-sm"
            style="margin-right:25px;"
          >
            <p class="white-txt">
              <a
                href="tel:+79213321380"
                class="head-ac-link"
              >+7 (921) 332-13-80</a>
            </p>
            <p class="white-txt">
              <a
                href="mail:pitomnik@sflora.ru"
                class="head-ac-link"
              >pitomnik@sflora.ru</a>
            </p>
          </div>

          <div class="hidden-xs hidden-sm">
            <p class="white-txt">
              Ленинградская область, <br>
              Гатчинский район, д. Парицы, <br>
              ул.Большая, д.69а
            </p>
          </div>
        </div>

        <!-- <cool-select
                    class="hidden-lg hidden-md hidden-sm"
                    :items="searchRes"
                    :placeholder="'Начните поиск'"
                    @search="onSearch"
                    :loading="loading"
                    :search-text.sync="searchInp"
                    disable-filtering-by-searc
                    item-text="nameR"
                    :disableFirstItemSelectOnEnter="true"
                    @keyup.enter="goToResults()"
                    ref="coolSel"
                    > -->
        <!-- <template slot="item" slot-scope="{ item: link }">
                        <router-link tag="div" :to="`/product/${link.slug}`" style="display: flex; align-items: center;" >
                            <img :src="link.image" class="search-flag"
                            v-if="link.image">
                            <div v-html="link.name"></div>
                        </router-link>
                    </template>

                    <div slot="before-items" class="slot" v-if="searchInp && searchInp !== '' ">
                        <router-link tag="div" :to="`/search-results=${searchInp}`" class="default-search">
                            Искать по запросу: {{searchInp}} <span class="mdi mdi-arrow-right"></span>
                        </router-link>
                    </div>

                    <template slot="input-end">
                        <span class="mdi mdi-close" @click="clearSearch"></span>
                    </template>

                    <template slot="no-data" >
                    Нет результатов
                    </template>
                </cool-select> -->
      </div>
    </div>
  </header>
</template>

<script>
import { CoolSelect } from "vue-cool-select";
import { mapGetters } from 'vuex'
import MobileNav from './MobileNav.vue'
import axios from 'axios'

export default {
    components: {MobileNav, CoolSelect},
    computed: {
        ...mapGetters({
            region: "catalog/getRegion",
            cart: "cart/getCart",
            user: "auth/getAuthenticated",
            loadSearch: "catalog/getLoadSearch",
            searchResults: "catalog/getSearchResultsPr",
        }),
    },
    watch: {
        mobileMenu(){
            if(this.mobileMenu === true){
                document.body.style.overflow = 'hidden'
            }else{
                document.body.style.overflow = 'visible'
            }
        }
    },  
    methods: {
        goToResults(){
            this.$router.replace(`/search-results=${this.searchForPage}`)
        },
        onSearch(search) {
            
            this.loading = true;
            this.searchForPage = search

            this.$store.dispatch('catalog/goSearch', search).then(()=>{
                this.searchRes = this.searchResults
                console.log(this.searchRes)
                this.loading = false
            })
            
        },
        clearSearch(){
            this.searchInp = ''
            this.searchRes = []
            // this.$store.dispatch('catalog/clearSearch')
            document.querySelector('.IZ-select input').value = 'null'
        },
        changeRegion(region){
            this.$store.dispatch("catalog/changeRegion", region)
        },
    },
    data(){
        return{
            searchForPage: '',
            loading: false,
            searchInp: '',
            searchRes: [],
            mobileMenu: false,
            regions: [
                {
                    txt: 'Санкт-Петербург и ЛО (самовывоз на весну)',
                },
                {
                    txt: 'Регионы (рассылка на август)',
                },
            ]
        }
    }
}
</script>

<style scoped>
.default-search{
    cursor: pointer;
    padding: 18px 15px !important;
    font-weight: 500;
}
</style>