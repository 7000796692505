<template>
    <div class="catalog-wrapper" v-if="categories.length">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="bredcrums">
                            <span>Каталог</span>
                        </div>



                        <h2>каталог растений</h2>
                        <FilterPanel />
                    </div>
                    <div class="col-lg-6 col-sm-6" v-for="(item, index) in categories" :key="index">
                        <CatCard :catItem="item" />
                    </div>

                    
                </div>
            </div>
        </div>
        <div class="catalog-wrapper loader-box" v-else>
            <div>
                <img src="@/assets/img/dlogo.svg" class="loader">
                <br>
                <h3>Загружаем товары</h3>
            </div>
        </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CatCard from '@/components/CatCard.vue'
import FilterPanel from '../components/ui/Filter.vue'

export default {
    components: { CatCard, FilterPanel },
    computed:{
        ...mapGetters({
            allGoods: "catalog/getAllGoods",
            categories: "catalog/getCategories"
        }),
    },
    data(){
        return{
            paginatedItems: [] ,
            customLabels: {
                first: 'Начало',
                last: 'В конец',
                previous: 'Пред.',
                next: 'След.'
            },
            pageSp: 1
        }
    },
    methods: {
        onChangePage(page){
            this.paginatedItems = page
            let options = {offset: 0,force: true}
            this.$scrollTo('.catalog-wrapper', 400, options)
        },
        getPage(e){
            if(e.target.innerText === 'Начало' || e.target.innerText === 'В конец' || e.target.innerText === 'Пред.' || e.target.innerText === 'След.'){
                return
            }
            this.$router.push(`#${e.target.innerText}`) 
        }
    },
    created(){
        if(this.$route.hash){
            this.pageSp = parseInt(this.$route.hash.replace(/#/, ''))
        }
    }
}
</script>