<template>
  <div class="pop-search">
    <div
      class="pop-search-box text-center"
      v-if="region === 'Санкт-Петербург и ЛО (самовывоз на весну)' "
    >
      <h3>ЗАКАЗ РАСТЕНИЙ ДЛЯ САНКТ-ПЕТЕРБУРГА И ОБЛАСТИ (САМОВЫВОЗ ИЗ ПИТОМНИКА И ТОЧЕК ВЫДАЧИ ЗАКАЗОВ ВЕСНОЙ 2025 ГОДА):</h3>
      <br>
      <p class="grey-txt">
        Заказы принимаются до 1 марта включительно. Обращаем ваше внимание, что все заказы будут выдаваться после 25 мая 2025 года. Нам необходимо дождаться пока все растения проснутся и убедиться, что мы отдаем живой здоровый материал!
        <br><br>
        <strong>Минимальная стоимость заказа — 2500 р.</strong> 
        <br><br>
        Вы оформляете заказ на сайте (Интернет-магазин), добавляя понравившиеся позиции в корзину. Для удобства вы можете заранее ознакомиться с полным списком растений, доступных для заказа (добавлен отдельным файлом). <br>
        В Корзине необходимо выбрать пункт самовывоза (Питомник, м. Нарвская, Пулковское шоссе, м. Горьковская или м. Девяткино) и указать данные для связи.<br>
        Внимательно проверяйте все заполненные поля. Свои пожелания вы можете указать в примечании.<br>
        При проведении оплаты откроется платежная система Банка Тинькофф. <br>
        <strong> Тинькофф – это банк нашего Интернет-магазина, оплачивать вы можете картой любого банка.</strong><br>
        После оформления заказа и оплаты в размере 100 % Вам на электронную почту придет чек об оплате и накладная. Если подтверждение не пришло – проверяйте папки спама и рассылки, при возникновении вопросов обращайтесь по телефону +79218634421.<br>
        Сделанный заказ означает, что Вы ознакомились с правилами размещения и выполнения заказов и согласны с ними.
      </p>
      <button
        class="to-cart"
        @click="hideRules"
      >
        Я ознакомлен с правилами доставки
      </button>
    </div>
    <div
      class="pop-search-box text-center"
      v-else
    >
      <h4>РАССЫЛКА РАСТЕНИЙ ПОЧТОЙ И ТРАНСПОРТНЫМИ КОМПАНИЯМИ В РЕГИОНЫ (АВГУСТ-СЕНТЯБРЬ 2025 ГОДА).</h4>
      <br>
      <p class="grey-txt">
        Почтовые заказы принимаются до 1 марта включительно. Обращаем ваше внимание, что все заказы отправляются только в конце сезона (с 15 августа по 15 сентября), возможны сдвиги в несколько дней из-за погоды (жара/сильные дожди).<br><br>
        <strong>Минимальная сумма заказа 2500 рублей.</strong>
        <br><br>
        1. Вы оформляете заказ на сайте (Интернет-магазин), добавляя понравившиеся
        позиции в корзину. Для удобства вы можете заранее ознакомиться с полным
        списком растений, доступных для заказа (добавлен отдельным файлом).
        В Корзине необходимо указать все контактные данные (ФИО, телефон, эл. почту),
        а также удобный способ доставки (Почта России, транспортные компании) и
        адрес. Обязательно проверяйте правильность заполнения всех данных, чтобы
        не возникало затруднений при отправке!
        При проведении оплаты откроется платежная система Банка Тинькофф. Тинькофф
        – это банк нашего Интернет-магазина, оплачивать вы можете картой
        любого банка. В оплату входит стоимость растений и подготовка их к
        пересылке. Растения отправляются в контейнере. За подготовку растения к
        пересылке мы добавляем к его стоимости 20 рублей.
        Оплата производится на сайте после оформления заказа и заполнения всех
        необходимых полей. После оплаты Вам на электронную почту придет чек и
        накладная. Если подтверждение не пришло – проверяйте папки «СПАМ» и
        «РАССЫЛКИ», при возникновении вопросов обращайтесь по телефону
        +79218634421.
        До терминала отправки транспортной компании (почтового отделения) доставляем
        заказ бесплатно. Все дальнейшие расходы по пересылке оплачиваются
        клиентом при получении заказа наложенным платежом по тарифам
        перевозчика.<br><br>
        2. Внимание! Маленькие колокольчики, обриеты, тимьяны, маленькие очитки
        трудно переносят пересылку. Вы заказываете их на свой риск. Дельфиниумы
        высылаем только с закрытой корневой системой: вес 1 растения не менее 500 г.
        Также обращаем ваше внимание, что у таких растений как: дицентра, зигаденус,
        додекатеон, пузырница и аквилегия к моменту отправки отмирает вся или
        практически вся надземная часть. Не пугайтесь, горшок не пустой, в нем
        здоровая сильная корневая система с почками, заложенными на будущий год.<br><br>
        3. График очередности отправки посылок определяется отдаленностью пункта
        получения заказа. Предварительно: с 16-22 августа Дальневосточный и
        Сибирский ФО, с 23 августа по 3 сентября Уральский, Северо-Западный и
        Центральный ФО, 4-15 сентября Приволжский, Южный, Северо-Кавказский ФО.<br><br>
        4. Сделанный заказ означает, что Вы ознакомились с правилами размещения и
        выполнения заказов и согласны с ними.
      </p>
      <button
        class="to-cart"
        @click="hideRules"
      >
        Я ознакомлен с правилами доставки
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default{
    computed: {
        ...mapGetters({
            region: "catalog/getRegion"
        })
    },
    methods:{
        hideRules() {
            this.$store.dispatch("catalog/controlRules", false)
        }
    }
}
</script>


<style scoped>
h4{
    margin-bottom: 0px;
}
.pop-search-box{
    min-height: inherit;
    max-height: 600px;
    width: 90%;
    overflow: scroll;
}
.grey-txt{
    font-size: 13px;
    line-height: 17px;
}
</style>