<template>
  <section id="contacts">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="bredcrums">
            <span><router-link
              tag="a"
              to="/"
            >Главная</router-link></span>
            <span>Контакты</span>
          </div>
        </div>
        <div class="col-lg-12">
          <h2 style="margin-bottom:22px;">
            КАК ДОБРАТЬСЯ ДО ПИТОМНИКА?
          </h2>
          <p class="black-txt">
            На общественном транспорте из Гатчины: от Варшавского или Балтийского вокзалов г. Гатчины автобусами № 521-524, 530, 536, 542 до остановки Парицы-2. Затем пешком 100 метров в обратную сторону и направо (через дорогу в сторону дома 69А) 100 метров.
            <br><br>
            На автомашине из Санкт-Петербурга: на Яндекс-навигаторе наберите “Северная флора”. Проверьте адрес: Парицы, ул. Большая 69а.
            <br><br>
            Или двигайтесь по Киевскому шоссе на Гатчину. Далее возможны два варианта проезда.<br>
            1) Проедьте Гатчину насквозь по ее центральной магистрали. После выезда из Гатчины на круговом движении сверните направо на Парицкое шоссе. Через километр начинается деревня Парицы. В середине деревни, слева от дороги, Вы увидите большой желтый указатель съезда к питомнику.<br>
            2) По Киевскому шоссе в объезд Гатчины. При подъезде к Большим Колпанам Вы увидите сначала слева, затем справа большие желто-зеленые ангары-магазины. Теперь появятся указатели прямо на Псков, Волосово и направо на Гатчину. Поверните направо. Через 2 км появится указатель на Парицкое шоссе.  Следуйте по Парицкому шоссе как и в пункте 1).
            <br><br>
            Если попадете в деревню Лядино, значит нужный поворот на Гатчину Вы проскочили, разворачивайтесь и выбирайте направление на Гатчину.
            <br><br>
          </p>
        </div>
        <div class="col-lg-4">
          <h3 style="margin-bottom:20px;">
            Адрес питомника
          </h3>
          <hr>
          <h4><span class="mdi mdi-map-marker" /> Гатчинский район, д. Парицы, ул. Большая, д.69а</h4>
          <h4><span class="mdi mdi-clock" />Пн-Сб с 09:00 до 18:00</h4>
          <h4><span class="mdi mdi-clock" />Воскресенье – выходной</h4>
          <h4><a href="tel:+79213321380"><span class="mdi mdi-phone" /> +7(921)332-13-80</a></h4>
          <h4><a href="tel:+79218634421"><span class="mdi mdi-phone" /> +7(921)863-44-21</a></h4>
          <h4><a href="mailto:pitomnik@sflora.ru"><span class="mdi mdi-email" /> pitomnik@sflora.ru</a></h4>
        </div>
        <div class="col-lg-8">
          <yandex-map
            :coords="coordinates"
            :scroll-zoom="false"
            :controls="[]"
            :zoom="13"
          >
            <ymap-marker 
              :coords="coordinates"
              :icon="markerIcon"
              :marker-id="markerIcon.id"
            >
              123
            </ymap-marker>
          </yandex-map>
        </div>
        <div class="col-lg-12">
          <br>
          <hr>
          <br>
        </div>
        <div class="col-lg-12">
          <h2 style="margin-bottom:22px;">
            КАК ДОБРАТЬСЯ ДО ТОРГОВОЙ ТОЧКИ “НАРВСКАЯ”?
          </h2>
          <p class="black-txt">
            ТОРГОВАЯ ТОЧКА “НАРВСКАЯ” находится в Санкт-Петербурге около ст.м. Нарвская. Адрес: ул. Промышленная, д.6, во дворе ЦДП «Нарвский». От станции метро пешком через подземный переход, слева в 50 метрах начинается Промышленная улица. ЦДП “Нарвский” расположен по ее левой стороне, а вход во двор через железные ворота со шлагбаумом в извилистой части Промышленной улицы.
            <br><br>
            Время работы: 10:00 — 19:30, в субботу 10:00 – 17:00, воскресенье – выходной. Возможны изменения в расписании в праздничные дни. Справки о наличии растений на точке по телефону 8(921)8629421.
            <br><br>
            Сезон работы: с 27 апреля до конца сентября.
            <br><br>
          </p>
        </div>
        <div class="col-lg-4">
          <h3 style="margin-bottom:20px;">
            ТОРГОВАЯ ТОЧКА “НАРВСКАЯ”
          </h3>
          <hr>
          <h4><span class="mdi mdi-map-marker" /> СПб, ст.м. Нарвская, ул. Промышленная, д.6, ЦДП “Нарвский” во дворе</h4>
          <h4><span class="red-alert">Закрыто с 4.10 до конца апреля</span></h4>
          <!-- <h4><span class="mdi mdi-clock" /><span style="color:green;font-size:14px;">Пн-пт с 10:00 до 19:30, сб с 10:00 до 17:00</span></h4> -->
          <!-- <h4><span class="mdi mdi-clock" /> Воскресенье – выходной</h4> -->
          <h4><a href="tel:+79218629421"><span class="mdi mdi-phone" /> +7(921)862-94-21  </a></h4>
          <h4><a href="mailto:pitomnik@sflora.ru"><span class="mdi mdi-email" /> pitomnik@sflora.ru</a></h4>
        </div>
        <div class="col-lg-8">
          <yandex-map
            :coords="coordinates2"
            :scroll-zoom="false"
            :controls="[]"
            :zoom="15"
          >
            <ymap-marker 
              :coords="coordinates2"
              :icon="markerIcon2"
              :marker-id="markerIcon2.id"
            >
              234
            </ymap-marker>
          </yandex-map>
        </div>
        <div class="col-lg-12">
          <br>
          <hr>
          <br>
        </div>
        <div class="col-lg-4">
          <h3 style="margin-bottom:20px;">
            ТОРГОВАЯ ТОЧКА “Грин Дом”
          </h3>
          <hr>
          <h4><span class="mdi mdi-map-marker" /> СПб, Пулковское шоссе, д 53 корп 2</h4>
          <!-- <h4><span class="red-alert">Закрыто до весны 2024 г.</span></h4> -->
          <!-- <h4><span class="mdi mdi-clock"></span><span style="color:green;font-size:14px;">Точка продаж откроется с 28.04.2023</span></h4> -->
          <!-- <h4><span class="mdi mdi-clock" />Пн-Сб с 10:00 до 19:00</h4>
          <h4><span class="mdi mdi-clock" />Воскресенье – выходной</h4> -->
          <h4><span class="red-alert">Закрыто с 4.10 до конца апреля</span></h4>
          <h4><a href="tel:+79217451913"><span class="mdi mdi-phone" /> +7 (921) 745-19-13</a></h4>
          <h4><a href="mailto:pitomnik@sflora.ru"><span class="mdi mdi-email" /> pitomnik@sflora.ru</a></h4>
        </div>
        <div class="col-lg-8">
          <yandex-map
            :coords="coordinates3"
            :scroll-zoom="false"
            :controls="[]"
            :zoom="15"
          >
            <ymap-marker 
              :coords="coordinates3"
              :icon="markerIcon3"
              :marker-id="markerIcon3.id"
            >
              567
            </ymap-marker>
          </yandex-map>
        </div>
        <!-- новые -->
        <div class="col-lg-12">
          <br>
          <hr>
          <br>
        </div>
        <div class="col-lg-4">
          <h3 style="margin-bottom:20px;">
            ТОРГОВАЯ ТОЧКА “Девяткино”
          </h3>
          <hr>
          <h4><span class="mdi mdi-map-marker" />  СПб, ст.м. Девяткино, г. Мурино, площадь Привокзальная, д.3</h4>
          <!-- <h4><span class="red-alert">Закрыто до весны 2024 г.</span></h4> -->
          <!-- <h4><span class="mdi mdi-clock"></span><span style="color:green;font-size:14px;">Точка продаж откроется с 28.04.2023</span></h4> -->
          <!-- <h4><span class="mdi mdi-clock" />Пн-сб с 10:00 до 19:00</h4>
          <h4><span class="mdi mdi-clock" />Воскресенье – выходной</h4> -->
          <h4><span class="red-alert">Закрыто с 4.10 до конца апреля</span></h4>
          <h4><a href="tel:+79116011048"><span class="mdi mdi-phone" /> +7(911)601-10-48</a></h4>
          <h4><a href="tel:+79218634421"><span class="mdi mdi-phone" /> +7(921)863-44-21</a></h4>
          <h4><a href="mailto:pitomnik@sflora.ru"><span class="mdi mdi-email" /> pitomnik@sflora.ru</a></h4>
        </div>
        <div class="col-lg-8">
          <yandex-map
            :coords="coordinates4"
            :scroll-zoom="false"
            :controls="[]"
            :zoom="15"
          >
            <ymap-marker 
              :coords="coordinates4"
              :icon="markerIcon4"
              :marker-id="markerIcon4.id"
            >
              568
            </ymap-marker>
          </yandex-map>
        </div>
        <div class="col-lg-12">
          <br>
          <hr>
          <br>
        </div>
        <div class="col-lg-4">
          <h3 style="margin-bottom:20px;">
            ТОРГОВАЯ ТОЧКА “Сытный”
          </h3>
          <hr>
          <h4><span class="mdi mdi-map-marker" /> СПб, ст.м. Горьковская, Петроградский район, Сытнинская улица, дом 5 лит. А</h4>
          <h4><span class="red-alert">Закрыто с 4.10 до конца апреля</span></h4>
          <!-- <h4><span class="mdi mdi-clock" />Пн-Сб с 10:00 до 18:00</h4>
          <h4><span class="mdi mdi-clock" />Воскресенье – с 10:00 до 17:00</h4> -->
          <h4><a href="tel:+79215766172"><span class="mdi mdi-phone" /> +7(921)576-61-72</a></h4>
          <h4><a href="mailto:pitomnik@sflora.ru"><span class="mdi mdi-email" /> pitomnik@sflora.ru</a></h4>
        </div>
        <div class="col-lg-8">
          <yandex-map
            :coords="coordinates6"
            :scroll-zoom="false"
            :controls="[]"
            :zoom="15"
          >
            <ymap-marker 
              :coords="coordinates6"
              :icon="markerIcon6"
              :marker-id="markerIcon6.id"
            >
              568
            </ymap-marker>
          </yandex-map>
        </div>
        <div class="col-lg-12">
          <br>
          <hr>
          <br>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { yandexMap, ymapMarker } from 'vue-yandex-maps'

    export default {
        data(){
            return{
                coordinates: [59.534172, 30.031875],
                coordinates2: [59.899231, 30.273507],
                coordinates3: [59.781736, 30.324052],
                coordinates4: [60.051315, 30.445136],
                coordinates6: [59.956940, 30.309364],
                markerIcon: {
                    id:234,
					layout: 'default#imageWithContent',
				    imageHref: require('../assets/img/mpmark.svg'),
				    imageSize: [55, 55],
				    imageOffset: [0, 0],
				},
                markerIcon2: {
                    id:123,
					layout: 'default#imageWithContent',
				    imageHref: require('../assets/img/mpmark.svg'),
				    imageSize: [55, 55],
				    imageOffset: [0, 0],
				},
                markerIcon3: {
                    id:567,
					layout: 'default#imageWithContent',
				    imageHref: require('../assets/img/mpmark.svg'),
				    imageSize: [55, 55],
				    imageOffset: [0, 0],
				},
                markerIcon4: {
                    id:568,
					layout: 'default#imageWithContent',
				    imageHref: require('../assets/img/mpmark.svg'),
				    imageSize: [55, 55],
				    imageOffset: [0, 0],
				},
                markerIcon5: {
                    id:569,
					layout: 'default#imageWithContent',
				    imageHref: require('../assets/img/mpmark.svg'),
				    imageSize: [55, 55],
				    imageOffset: [-25, -40],
				},
                markerIcon6: {
                    id:570,
					layout: 'default#imageWithContent',
				    imageHref: require('../assets/img/mpmark.svg'),
				    imageSize: [55, 55],
				    imageOffset: [-25, -40],
				},
            }
        }
    }
</script>

<style scoped>
.ymap-container{
    height: 500px;
}
h4{
    margin-bottom: 25px;
}
h4 a{
    color: #000 ;
}
h4 a:hover{
    color: #9BBE3B;
}
h4 span{
    color: #999;
    margin-right: 5px;
    font-size: 20px;
}
.red-alert{
    font-size: 14px;
    color: #fff;
    background: #E56036;
    padding: 4px;
    border-radius: 4px;
}
</style>