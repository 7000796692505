<template>
        <div class="search-results" v-if="likes.length">
        <router-link tag="div" @click.native="closeSearch" class="cat-title" 
        v-for="(link, index) in likes" :key="index" 
                   :to="`/product/${link.slug}`" >
                        <img :src="link.picture" alt="">
                        <h5>{{link.name}}</h5>
                   </router-link>
        </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    props: {
        wishlist: {
            type: Array,
            required: true
        }
    },
    computed: {
        ...mapGetters({
            allGoods: "catalog/getAllGoods",
        })
    },
    watch: {
        allGoods(){
            if(this.allGoods.length){
                this.allGoods.forEach(item =>{
                    this.wishlist.forEach(like =>{
                        if(item.id == like){
                            this.likes.push(item)
                        }
                    })
                })
            }
        }
    },
    created(){
        if(this.allGoods.length){
                this.allGoods.forEach(item =>{
                    this.wishlist.forEach(like =>{
                        if(item.id == like){
                            this.likes.push(item)
                        }
                    })
                })
            }
    },
    data(){
        return{
            likes: []
        }
    },
}
</script>

<style scoped>
.search-results{
    overflow: inherit;
    height: inherit;
    max-height: inherit;
}
</style>