<template>
    <div>
        <section id="inner">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                         <p class="big-wh">О ПИТОМНИКЕ</p>
                        <h1>СЕВЕРНАЯ ФЛОРА</h1>
                    </div>
                </div>
            </div>
        </section>

        <section id="story">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6">
                        <img src="../assets/img/IMG_2514.jpg" class="about-img">
                    </div>
                    <div class="col-lg-6">
                        <h2>История</h2>
                        <p class="grey-txt">
                            
                            Питомник заложен в 2002 году в 5 км от Гатчины (50 км от Санкт-Петербурга) в деревне Парицы кандидатом биологических наук, коллекционером растений <strong>Мартикайнен Надеждой Федоровной</strong> на участке площадью около 1 га. 
                            <br><br>     
                            Основной целью создаваемого питомника было выращивание здоровых сортовых растений с закрытой корневой системой, пригодных для климатических условий Северо-Западного региона России. 
                            Прошли годы, мы по-прежнему черенкуем и сеем, испытываем и вводим в ассортимент новые виды и сорта, тщательно следим за сортовой чистотой и качеством растений. 
                            Посадочный материал, как в питомнике, так и на торговых точках Петербурга, продаём только в контейнерах, и только свой. 
                            Перепродажей растений не занимаемся.

                        </p>
                    </div>
                </div>
            </div>
        </section>


        <section id="numbers">
            <div class="container">
                <div class="col-lg-3">
                    <div class="big-number">
                        <p class="black-txt"><span>{{year.getFullYear() - 2002}}</span> <br> ЛЕТ</p>
                        <h4>успешной работы</h4>
                    </div>
                </div>
                <div class="col-lg-3">
                    <div class="big-number">
                        <p class="black-txt"><span>1</span><br> ГЕКТАР</p>
                        <h4>Площадь питомника</h4>
                    </div>
                </div>
                <div class="col-lg-3">
                    <div class="big-number">
                        <p class="black-txt"><span>1000</span> <br> СОРТОВ</p>
                        <h4>Многолетних садовых цветов</h4>
                    </div>
                </div>
                <div class="col-lg-3">
                    <div class="big-number">
                        <p class="black-txt"><span>150</span> <br> ВИДОВ</p>
                        <h4>Декоративных лиственных кустарников</h4>
                    </div>
                </div>
            </div>
        </section>

        <section id="story">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6">
                        <h2>Каким видим своего клиента?</h2>
                        <p class="grey-txt">
                            Прежде всего, это любитель растений, который сам обустраивает свой сад, получая от этого и творческое, и эстетическое удовольствие. Для любых условий сада можно найти правильные растения. Именно поэтому, у нас широкий выбор видов и сортов, а каталог растений содержит подробные характеристики растений. Все описания и фотографии сделаны нами, отражают размеры растений и время цветения в Ленинградской области.
                        </p>
                    </div>
                     <div class="col-lg-6">
                        <img src="../assets/img/about1.jpg" class="about-img">
                    </div>
                </div>
            </div>
        </section>

        <section id="story" style="background-color: #F5F5F5;">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6">
                        <img src="../assets/img/about2.jpg" class="about-img">
                    </div>
                    <div class="col-lg-6">
                        <h2>Ассортимент</h2>
                        <p class="grey-txt">
                            В ассортименте питомника более 1000 сортов многолетних садовых цветов и 150 сортов декоративных лиственных кустарников. Мы гордимся коллекцией почвопокровных растений, новозеландских дельфиниумов и, конечно, флоксов метельчатых. Выращивание многолетников – дело кропотливое, требующее знаний, ежедневной напряжённой и упорной работы, выполняемой с любовью.  В коллективе питомника практически нет текучки, половина сотрудников работает более 10 лет.
                            <br><br>
                            Многолетние цветы продаются в контейнерах 0,5, 1,0, 1,3 литра, лиственные кустарники в контейнерах 1,3 либо 1,5 литра. Не стоит рассчитывать найти у нас крупномеры.

                            <br><br>
                            Все растения выращиваются по традиционной технологии и зимуют прямо в контейнерах, под открытым небом, укрытые только снегом. Именно поэтому, мы их не перекармливаем азотом, предпочитая не гнаться за размерами, а иметь небольшие, но коренастые, с плотными, устойчивыми к зимовке тканями и отлично развитой корневой системой растения. Уверены, что наши питомцы не упустят свой шанс выжить и быстро начать активный рост, будучи высаженными на обычных тощих суглинках.
                        </p>
                    </div>
                </div>
            </div>
        </section>


        <Submit />

    </div>
</template>

<script>
import Submit from '../components/Submit.vue'

export default {
    components: {
        Submit
    },
    data(){
        return{
            year: new Date
        }
    }
}
</script>