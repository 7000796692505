<template>
    <footer>
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                     <cool-select
                    class="hidden-lg hidden-md hidden-sm"
                    :items="searchRes"
                    :placeholder="'Начните поиск'"
                    @search="onSearch"
                    :loading="loading"
                    :search-text.sync="searchInp"
                    disable-filtering-by-searc
                    item-text="nameR"
                    :disableFirstItemSelectOnEnter="true"
                    @keyup.enter="goToResults()"
                    ref="coolSel"
                    > <template slot="item" slot-scope="{ item: link }">
                        <router-link tag="div" :to="`/product/${link.slug}`" style="display: flex; align-items: center;" >
                            <img :src="link.image" class="search-flag"
                            v-if="link.image">
                            <div v-html="link.name"></div>
                        </router-link>
                    </template>

                    <div slot="before-items" class="slot" v-if="searchInp && searchInp !== '' ">
                        <router-link tag="div" :to="`/search-results=${searchInp}`" class="default-search">
                            Искать по запросу: {{searchInp}} <span class="mdi mdi-arrow-right"></span>
                        </router-link>
                    </div>

                    <template slot="input-end">
                        <span class="mdi mdi-close" @click="clearSearch"></span>
                    </template>

                    <template slot="no-data" >
                    Нет результатов
                    </template>
                </cool-select>

                </div>
                <div class="col-lg-4">
                    <router-link tag="a" to="/">
                        <img src="@/assets/img/dlogo.svg" style="margin-bottom:20px;">
                    </router-link>
                    <p class="small-black">Питомник заложен в 2002 году на участке <br> площадью около 1 га в деревне Парицы, <br> в 5 км от Гатчины (50 км от Санкт-Петербурга).</p>
                    <div class="asotiation-box">
                        <a href="https://www.ruspitomniki.ru/" target="_blank">
                            <img src="@/assets/img/appm_logo.svg" class="asotiation">
                        </a>
                    <p class="small-black">Член ассоциации производителей посадочного материала</p>
                    </div>
                </div>
                <div class="col-lg-8">
                    <div class="flex-footer">
                        <div class="footer-box">
                            <h5>карта сайта</h5>
                            <ul>
                                <router-link tag="li" to="/">ГЛАВНАЯ</router-link>
                                <router-link tag="li" to="/o-nas">О ПИТОМНИКЕ</router-link>
                                <router-link tag="li" to="/product-categoory">КАТАЛОГ</router-link>
                                <router-link tag="li" to="/product-category">ГАЛЕРЕЯ</router-link>
                                <router-link tag="li" to="/contacts">КОНТАКТЫ</router-link>
                            </ul>
                        </div>
                        <div class="footer-box">
                            <h5>КАТЕГОРИИ </h5>
                            <ul v-if="categories.length">
                                <router-link tag="li" v-for="(cat, index) in categories" :key="index"
                                :to="`/product-category/${cat.slug}`">{{cat.name}}</router-link>
                                <router-link tag="li" 
                                to="/pechatnaya-produkcziya">ПЕЧАТНАЯ ПРОДУКЦИЯ</router-link>
                            </ul>
                        </div>
                        <div class="footer-box">
                            <h5>КОНТАКТЫ</h5>
                            <a href=""><p class="dark-link">pitomnik@sflora.ru</p></a>
                            <a href=""><p class="dark-link">+7(921)332-13-80</p></a>
                            <p class="dark-link">Ленинградская область, <br> Гатчинский район, д. Парицы, <br> ул.Большая, д.69а</p>
                            <div class="social-links">
                                <!-- <a href="https://www.instagram.com/sever.flora/" target="blank_">
                                    <img src="@/assets/img/inst.svg" alt="">
                                </a> -->
                                <a href="https://wa.me/79213321380" target="blank_">
                                    <img src="@/assets/img/wh.svg" alt="">
                                </a>
                                <a href="https://vk.com/sever.flora" target="blank_">
                                    <img src="@/assets/img/vk.svg" alt="">
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12">
                    <div class="copyright">
                        <router-link tag="a" to="/contacts" class="navigator"><img src="@/assets/img/navi.svg" alt="">Посмотреть на карте</router-link>
                        <a href="https://webink.site/" class="navigator">Разработка - webink</a>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
import { mapGetters } from 'vuex'
import { CoolSelect } from "vue-cool-select"
import axios from 'axios'

export default{
    components: {CoolSelect},
    computed: {
        ...mapGetters({
            categories: "catalog/getCategories",
            loadSearch: "catalog/getLoadSearch",
            searchResults: "catalog/getSearchResultsPr",
        })
    },
    data(){
        return{
            searchForPage: '',
            loading: false,
            searchInp: '',
            searchRes: [],
        }
    },
    methods: {
        goToResults(){
            this.$router.replace(`/search-results=${this.searchForPage}`)
        },
        onSearch(search) {
            
            this.loading = true;
            this.searchForPage = search

            this.$store.dispatch('catalog/goSearch', search).then(()=>{
                this.searchRes = this.searchResults
                console.log(this.searchRes)
                this.loading = false
            })
            
        },
        clearSearch(){
            this.searchInp = ''
            this.searchRes = []
            // this.$store.dispatch('catalog/clearSearch')
            document.querySelector('.IZ-select input').value = 'null'
        },
    }
}
</script>

<style scoped>
.asotiation{
    height: 60px;
    margin-right: 10px;
}
/* .asotiation-box{
    justify-content: flex-start;
    align-items: center;
    display: flex;
} */
.asotiation-box img{
    height: 50px;
    margin-bottom: 20px;
}
.asotiation-box p{
    margin-bottom: 20px;
    margin-top: 0;
    max-width: 80%;
}
.default-search{
    cursor: pointer;
    padding: 18px 15px !important;
    font-weight: 500;
}
.IZ-select{
    margin-bottom: 30px;
}
</style>