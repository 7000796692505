<template>
  <div
    class="catalog-wrapper"
    v-if="categories.length"
  >
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="bredcrums">
            <span><router-link
              tag="a"
              to="/product-category"
            >Каталог</router-link></span>
            <span>{{ getCategory().name }}</span>
          </div>
          <h2>{{ getCategory().name }}</h2>
          <FilterPanel />
          <Alphabet
            v-if="getCategory().child.length"
            :categories="getCategory().child"
          />
          <div
            class="sort-goods text-right"
            v-else
          >
            <button
              class="chbx"
              :class="{filtered : inStockOnly}"
              @click="inStockOnly = !inStockOnly"
            >
              <span
                class="mdi" 
                :class=" inStockOnly ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline'"
              />
              Показывать только в наличии
            </button>
          </div>
        </div>


        <div v-if="getCategory().child.length">
          <div
            class="col-lg-12 col-sm-12"
            v-for="(item, index) in getCategory().child"
            :key="index"
          >
            <SubCat
              :cat-item="item"
              v-show="checkVisibility(item.name[0])"
            />
          </div>
        </div>
                
        <div v-else>
          <div
            class="col-lg-4  col-sm-6"
            v-for="(item, index) in paginatedItems"
            :key="index" 
            v-show="inStockOnly && item.inStock !== 'instock' ? false : true "
          >
            <GoodCard :good-item="item" />
            <!-- {{ item.inStock }} -->
          </div>
        </div>

        <!-- paging -->
        <div
          class="col-lg-12 text-center"
          @click="getPage($event)"
          v-if="!getCategory().child.length"
        >
          <jw-pagination
            :items="singleCat.items"
            @changePage="onChangePage"
            :page-size="36"
            :labels="customLabels"
            :initial-page="pageSp"
          />
        </div>
      </div>
    </div>
  </div>
  <div
    class="catalog-wrapper loader-box"
    v-else
  >
    <div>
      <img
        src="@/assets/img/dlogo.svg"
        class="loader"
      >
      <br>
      <h3>Загружаем товары</h3>
      <div class="mini-loader">
        <div class="loader-progress" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import SubCat from '../components/SubCat.vue'
import GoodCard from '../components/GoodCard.vue'
import FilterPanel from '../components/ui/Filter.vue'
import Alphabet from '../components/ui/Alphabet.vue'

export default {
    props: ["cat"],
    components: { SubCat, GoodCard, FilterPanel, Alphabet },
    computed: {
        ...mapGetters({
            singleCat :"catalog/getSingleCat",
            categories: "catalog/getCategories",
            activeFilterLetter: 'catalog/getActiveFilterLetter'
        })
    },
    data(){
        return{
            inStockOnly: false,
            paginatedItems: [],
            customLabels: {
                first: 'Начало',
                last: 'В конец',
                previous: 'Пред.',
                next: 'След.'
            },
            pageSp: 1
        }
    },
    watch:{
        cat(){
            this.pageSp = 1
            this.$store.dispatch('catalog/loadSingleCat', this.cat)
        },
        $route (to, from){
            this.$store.dispatch('catalog/setActiveFilterLetter', '')
        }
    },
    created(){
        if(this.$route.hash){
            this.pageSp = parseInt(this.$route.hash.replace(/#/, ''))
            console.log(this.pageSp)
        }
        this.$store.dispatch('catalog/loadSingleCat', this.cat)
    },
    methods: {
        checkVisibility(letter){
            if(this.activeFilterLetter === ''){
                return true
            }

            if(this.activeFilterLetter === letter){
                return true
            }else{
                return false
            }
        },
        getCategory(){
            let category = this.categories.find(item =>{
                return item.slug === this.cat
            })

            return category
        },
        onChangePage(page){
            this.paginatedItems = page
            let options = {offset: 0,force: true}
            this.$scrollTo('.catalog-wrapper', 400, options)
        },
        getPage(e){
            if(e.target.innerText === 'Начало' || e.target.innerText === 'В конец' || e.target.innerText === 'Пред.' || e.target.innerText === 'След.'){
                return
            }
            this.$router.push(`#${e.target.innerText}`) 
        }
    },
}
</script>
<style scoped>
.col-lg-12{
    min-height: 0px !important;
}
</style>