<template>
        <div class="sidebar-wrapper hidden-xs hidden-sm">
            <ul v-if="categories.length">
                <router-link tag="div" :to="`/product-category/${cat.slug}`" v-for="(cat, index) in categories" :key="index">
                    <div class="cat-title" :class="{'act-cat' : index == subActive}" @click="openCat(index)">
                        <h5>{{cat.name}}</h5>
                        <img src="@/assets/img/arrd.svg" v-if="cat.child.length">
                    </div>
                    <ul class="sub-links" v-if="index == subActive && cat.child.length">
                        <router-link tag="li"  
                        class="grey-link" 
                        v-for="(sub, id) in cat.child" :key="id"
                        :to="`/product-category/${cat.slug}/${sub.slug}`">
                          <span v-if="sub.count != 0">  {{sub.name}}</span>
                          <!-- ({{sub.count}}) -->
                        </router-link>
                    </ul>
                </router-link>
                <!-- <li>
                    <div class="cat-title">
                        <router-link tag="h5" to="/new-products">
                            <span class="mdi mdi-star"></span>
                            Новинки
                        </router-link>
                    </div>
                </li> -->
                <li>
                    <div class="cat-title">
                        <router-link tag="h5" to="/certificate">
                            <span class="mdi mdi-gift"></span>
                            Сертификат
                        </router-link>
                    </div>
                </li>
                <li>
                    <div class="cat-title">
                        <router-link tag="h5" to="/delivery">
                            <span class="mdi mdi-map-marker"></span>
                            Доставка
                        </router-link>
                    </div>
                </li>
            </ul>
        </div>

</template>

<script>
export default {
    props: {
        categories: {
            required: true,
            type: Array
        }
    },
    data(){
        return{
            subActive: null,
            mobileSidebar: false
        }
    },
    watch: {
        categories(){
           for(let i = 0; i < this.categories.length; i++){
                if(this.categories[i].slug === this.$route.params.cat){
                    this.subActive = i
                }
            }
        }
    },
    methods: {
        openCat(index){
            if(index == this.subActive){
                this.subActive = null
            }else{
                this.subActive = index
            }
        }
    }
}
</script>