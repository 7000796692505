<template>
    <div class="catalog-wrapper" v-if="сertificate.length">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <h2>Подарочный сертификат</h2>
                </div>
                <div class="col-lg-4  col-sm-6" v-for="(item, index) in paginatedItems" :key="index">
                    <GoodCard :goodItem="item" />
                </div>

            
                <div class="row">
                    <div class="col-lg-12 text-center">
                        <jw-pagination :items="сertificate" @changePage="onChangePage" :pageSize="36" :labels="customLabels">
                        </jw-pagination>
                    </div>
                </div>

            </div>
        </div>
    </div>
   <div class="catalog-wrapper loader-box" v-else>
        <div>
            <img src="@/assets/img/dlogo.svg" class="loader">
            <br>
            <h3>Загружаем товары</h3>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import GoodCard from '../components/GoodCard.vue'

export default {
    components: {GoodCard},
    computed: {
        ...mapGetters({
            сertificate :"catalog/getCertificate"
        })
    },
    data(){
        return{
            paginatedItems: [],
            customLabels: {
                first: 'Начало',
                last: 'В конец',
                previous: 'Пред.',
                next: 'След.'
            }
        }
    },
    methods: {
        onChangePage(page){
            this.paginatedItems = page
            let options = {offset: 0,force: true}
            this.$scrollTo('.catalog-wrapper', 400, options)
        }
    },
}
</script>