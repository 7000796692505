<template>
  <section >
    <Main />
    <Categories />
    <News />
    <Popular :slpw="4" />
    <!-- <NewGoods /> -->
    <Submit />
  </section>
</template>


<script>
import Main from '@/components/Main.vue'
import Categories from '../components/Categories.vue'
import Popular from '../components/Popular.vue'
import News from '../components/News.vue'
// import NewGoods from '../components/NewGoods.vue'
import Submit from '../components/Submit.vue'

  export default {
    components: {
      Main,
      News,
      Submit,
      Categories,
      Popular
    }   
  }
</script>

