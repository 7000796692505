<template>
    <div>
        <!-- <pre>{{sales}}</pre> -->
        <div class="order-item" v-for="(item, index) in sales" :key="index">
            <div class="row">
                <div class="col-lg-6">
                    <p class="small-txt">Дата создания</p>
                    <h3>{{item.date_created}}</h3>
                </div>
                <div class="col-lg-6">
                    <p class="small-txt">Статус</p>
                    <h3>{{getStatus(item.status)}}</h3>
                </div>
                
                <div class="col-lg-12">
                    <br>
                    <p class="small-txt">Товары</p>

                    <table>
                        <tr>
                            <td><strong>Наименование</strong></td>
                            <td><strong>Кол-во штук</strong></td>
                            <td><strong>Цена</strong></td>
                        </tr>
                        <tr v-for="(good, ind) in item.cart" :key="ind">
                            <td>{{good.name}}</td>
                            <td>{{good.count}}</td>
                            <td>{{good.total}}</td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        sales: {
            required: true,
            type:  Array
        }
    },
    methods: {
        getStatus(status){
            if(status === 'pending'){
                return 'Ожидание'
            }
            if(status === 'completed'){
                return 'Выполнен'
            }
        }
    }
}
</script>

<style scoped>
.order-item{
    padding: 20px 0;
    border-bottom: 1px #F5F5F5 solid;
}
table{
    width: 100%;
}
</style>