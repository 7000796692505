<template>
    <section id="gallery">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <h2>В нашем питомнике</h2>
                </div>
					<!-- <div class="col-lg-6">
						<div class="teplobg1" :style="{'background-image': 'url(' + gal1 + ')'}"></div>
					</div> -->
					<!-- <div class="col-lg-3">
						<div class="teplobg1" :style="{'background-image': 'url(' + gal2 + ')'}"></div>
					</div> -->
					<!-- <div class="col-lg-3">
						<div class="row">
							<div class="col-lg-12">
								<div class="teplobg2" :style="{'background-image': 'url(' + gal3 + ')'}"></div>
							</div>
							<div class="col-lg-12">
								<div class="teplobg2" :style="{'background-image': 'url(' + gal4 + ')'}"></div>
							</div>
						</div>
					</div>
					<div class="col-lg-3">
						<div class="teplobg1" :style="{'background-image': 'url(' + gal5 + ')'}"></div>
					</div>
					<div class="col-lg-3">
						<div class="row">
							<div class="col-lg-12">
								<div class="teplobg2" :style="{'background-image': 'url(' + gal6 + ')'}"></div>
							</div>
							<div class="col-lg-12">
								<div class="teplobg2" :style="{'background-image': 'url(' + gal7 + ')'}"></div>
							</div>
						</div>
					</div>
					<div class="col-lg-6">
						<div class="teplobg1" :style="{'background-image': 'url(' + gal8 + ')'}"></div>
					</div> -->
				</div>
				<div class="row" v-for="(item, index) in gallery" :key="index">
					<div class="col-lg-12">
						<h4 style="margin-bottom:22px;font-size: 20px;">{{item.name}}</h4>
					</div>
					<div class="col-lg-4" v-for="(img, i) in item.gallery" :key="i">
						<div class="teplobg1" :style="{'background-image': 'url(' + img + ')'}"></div>
					</div>
				</div>
        </div>
    </section>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
	computed: {
		...mapGetters({
			gallery: "content/getGallery"
		})
	},
    data(){
        return{
            gal1: require('../assets/img/IMG_2514.jpg'),
			gal2: require('../assets/img/IMG_2515.jpg'),
			gal3: require('../assets/img/IMG_2516.jpg'),
			gal4: require('../assets/img/IMG_2516.jpg'),
			gal5: require('../assets/img/IMG_2517.jpg'),
			gal6: require('../assets/img/IMG_2518.jpg'),
			gal7: require('../assets/img/IMG_2514.jpg'),
			gal8: require('../assets/img/IMG_2515.jpg'),
        }
    }
}
</script>