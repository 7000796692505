<template>
    <div class="ctp-wrapper">

        <SidebarWrapper :categories="categories" />
        <SidebarWrapperMob :categories="categories" />
        
        <router-view></router-view>

        
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import SidebarWrapper from '@/components/ui/SidebarWrapper.vue'
import SidebarWrapperMob from '@/components/ui/SidebarWrapperMob.vue'

export default {
    components: { SidebarWrapper, SidebarWrapperMob },
    computed: {
        ...mapGetters({
            categories: "catalog/getCategories"
        })
    },
}
</script>