<template>
    <section id="gallery">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <h2 style="text-transform:none;">Результаты поиска по запросу:
                        <span style="color:#9bbe3a;">"{{id}}"</span>
                    </h2>
                </div>

            </div>
            <div class="row" v-if="searchResults.length">
                <div class="col-lg-12">
                    <h2>Товары</h2>
                </div>
                <div class="col-lg-3" v-for="(link, i) in searchResults" :key="i">
                    <div class="good-item mb-10">
                        <router-link tag="a" :to="`/product/${link.slug}`">
                            <div class="img-box">
                                <div class="first-img" v-lazy:background-image="link.image"></div>
                            </div>
                        </router-link>
                        <div class="under-img">
                            <router-link tag="a" :to="`/product/${link.slug}`">
                                <h4 v-html="link.name"></h4>
                            </router-link>
                        </div>
                    </div>
                </div> 
            </div>
            <div class="row" v-if="searchCats.length">
                <div class="col-lg-12">
                    <h2>Категории</h2>
                </div>
                <div class="col-lg-3" v-for="(link, i) in searchCats" :key="i">
                    <div class="good-item mb-10">
                        <router-link tag="a" :to="`/product-category${link.url}`">
                            <div class="img-box">
                                <div class="first-img" v-lazy:background-image="link.image"></div>
                            </div>
                        </router-link>
                        <div class="under-img">
                            <router-link tag="a" :to="`/product-category${link.url}`">
                                <h4 v-html="link.name"></h4>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import {
        mapGetters
    } from 'vuex'
    import axios from 'axios'

    export default {
        props: ["id"],
        computed: {
            ...mapGetters({
                loadSearch: "catalog/getLoadSearch",
                searchResults: "catalog/getSearchResultsPr",
                searchCats: "catalog/getSearchResultsCt" 
            }),
        },
    }
</script>

<style scoped>
.under-img{
    min-height: 57px;
}
</style>