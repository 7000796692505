<template>
    <div class="pop-search">
        <div class="text-center cat-selection">
            <h3>Выберите регион:</h3>
            <div  v-for="(item, index) in regions" :key="index">
                <button class="to-cart" @click="changeRegion(item.txt)">{{item.txt}}</button>
            </div>
        </div>
    </div>
</template>

<script>

export default{
    methods: {
        changeRegion(region){
            this.$store.dispatch("catalog/changeRegion", region)
            this.$store.dispatch("catalog/controlRules", true)
        },
    },
    data(){
        return{
            regions: [
                {
                    txt: 'Санкт-Петербург и ЛО (самовывоз на весну)',
                },
                {
                    txt: 'Регионы (рассылка на август)',
                },
            ]
        }
    }
}
</script>
