<template>
    <section id="enter">
        <div class="container">
            <div class="row">
                <div class="col-lg-8 col-lg-offset-2">
                    <form @submit.prevent="signUp" class="text-center">
                        <h3>Регистрация</h3>
                        <br><br>
                        <div class="row text-left">
                            <div class="col-lg-4">
                                <label for="">Фамилия</label>
                                 <input type="text" placeholder="Фамилия" v-model="surname"
                                 :class="{errorInp : $v.surname.$dirty && !$v.surname.required}">
                            </div>
                            <div class="col-lg-4">
                                <label for="">Имя</label>
                                 <input type="text" placeholder="Имя" v-model="cname"
                                 :class="{errorInp : $v.cname.$dirty && !$v.cname.required}">
                            </div>
                            <div class="col-lg-4">
                                <label for="">Отчество</label>
                                 <input type="text" placeholder="Отчество" v-model="fatherName"
                                 :class="{errorInp : $v.fatherName.$dirty && !$v.fatherName.required}">
                            </div>

                            <div class="col-lg-4">
                                <label for="">E-mail</label>
                                 <input type="text" placeholder="E-mail" v-model="email"
                                 :class="{errorInp : ($v.email.$dirty && !$v.email.required) || ($v.email.$dirty && !$v.email.email)}">
                            </div>
                            <div class="col-lg-4">
                                <label for="">Пароль</label>
                                 <input type="password" placeholder="Пароль" v-model="pass1"
                                 :class="{errorInp : ($v.pass1.$dirty && !$v.pass1.required) || ($v.pass1.$dirty && !$v.pass1.minLength)}">
                            </div>
                            <div class="col-lg-4">
                                <label for="">Подтверждение пароля</label>
                                 <input type="password" placeholder="Подтверждение пароля" v-model="pass2"
                                 :class="{errorInp : ($v.pass2.$dirty && !$v.pass2.required) || ($v.pass2.$dirty && !$v.pass2.minLength)}">
                            </div>
                        </div>

                        <p class="err" v-if="passNotValid">Введенные пароли не совпадают</p>


                        <button v-if="!load" class="to-cart">Зарегистрироваться</button>
                        <loader v-else />
                        <p class="err" v-if="signErr"><br> Пользователь с таким логином уже существует!</p>
                        <br><br>
                        <p class="small-txt">
                            Уже есть аккаунт?
                            <router-link tag="a" to="/enter">Войти</router-link>
                        </p>
                    </form>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { required, email, minLength } from "vuelidate/lib/validators";
import loader from '../components/ui/loader.vue'
import {mapActions} from 'vuex'

export default {
    components: {loader},
    computed: {
        passNotValid(){
            if(this.pass1 !== this.pass2){
                return true
            }else{
                return false
            }
        }
    },
    data(){
        return{
            surname: '',
            cname: '',
            fatherName: '',
            email: '',
            pass1: '',
            pass2: '',
            signErr: false,
            load: false
        }
    },
    validations: {
            surname: {
                required
            },
            cname: {
                required
            },
            fatherName: {
                required
            },
            email: {
                required, 
                email
            },
            pass1: {
                required,
                minLength: minLength(7)
            },
            pass2: {
                required,
                minLength: minLength(7)
            },
    },
    methods: {
        ...mapActions({
                SIGN_UP: "auth/SIGN_UP",
            }),
        signUp(){
            if(this.$v.$invalid) {
                this.$v.$touch();
                return;
            }
            if(this.passNotValid){
                return
            }

            this.load = true

            let form = {
                    surname: this.surname,
                    cname: this.cname,
                    fatherName: this.fatherName,
                    email : this.email,
                    pass1: this.pass1
                }

            this.SIGN_UP(form).then((res) => {
                    if(res === 'Вы уже зарегистрированы'){
                        this.signErr = true
                        this.load = false
                    }else{
                        this.signErr = false
                        this.load = false
                        this.$router.replace("/enter");
                    }
		        });

            
        }
    }
}
</script>

<style scoped>
.small-txt a{
    color: #9BBE3B;
}
.err{
    color: red;
}
.errorInp{
    border: 1px red solid !important;
}
</style>