import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import catalog from './catalog'
import cart from './cart'
import auth from './auth'
import content from './content'


export default new Vuex.Store({
	modules: {
		catalog,
		cart,
		auth,
		content
	}
}) 