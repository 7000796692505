<template>
    <div class="catalog-wrapper" v-if="singleSub">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="bredcrums">
                        <span><router-link tag="a" to="/product-category">Каталог</router-link></span>
                        <span><router-link tag="a" :to="`/product-category/${singleSub.parentCategory.slug}`">
                        {{singleSub.parentCategory.name}}</router-link></span>
                        <span>{{singleSub.category.name}}</span>
                    </div>
                    <h2>{{singleSub.category.name}}</h2>
                    <FilterPanel />
                    <div class="sort-goods text-right">
                        <button class="chbx" :class="{filtered : inStockOnly}"
                        @click="inStockOnly = !inStockOnly">
                            <span class="mdi" 
                            :class=" inStockOnly ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline'"></span>
                            Показывать только в наличии
                        </button>
                        <button :class="{filtered : priceSort !== null}" @click="sortGoodsPrice">Цена
                            <div class="mdi mdi-sort-reverse-variant" v-if="priceSort === false || priceSort === null"></div>
                            <div class="mdi mdi-sort-variant" v-if="priceSort === true"></div>
                        </button>
                    </div>
                </div>
                <div class="col-lg-4  col-sm-6" v-for="(item, index) in paginatedItems" :key="index"
                v-show="inStockOnly && item.inStock !== 'instock' ? false : true ">
                    <GoodCard :goodItem="item" />
                </div>

                    <div class="col-lg-12 text-center" @click="getPage($event)">
                        <jw-pagination :items="singleSub.items" @changePage="onChangePage" :pageSize="36" :labels="customLabels" :initialPage="pageSp">
                        </jw-pagination>
                    </div>
            </div>
        </div>
    </div>
    <div class="catalog-wrapper loader-box" v-else>
        <div>
            <img src="@/assets/img/dlogo.svg" class="loader">
            <br>
            <h3>Загружаем товары</h3>
            <div class="mini-loader">
                <div class="loader-progress"></div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import GoodCard from '../components/GoodCard.vue'
import FilterPanel from '../components/ui/Filter.vue'

export default {
    props: ["sub"],
    components: {GoodCard, FilterPanel},
    data(){
        return{
            inStockOnly: false,
            priceSort: null,
            paginatedItems: [],
            customLabels: {
                first: 'Начало',
                last: 'В конец',
                previous: 'Пред.',
                next: 'След.'
            },
            pageSp: 1,
        }
    },
    computed: {
        ...mapGetters({
            singleSub: "catalog/getSingleSub",
            region: "catalog/getRegion",
        })
    },
    watch:{
        sub(){
            this.pageSp = 1
            this.$store.dispatch('catalog/loadSingleSub', this.sub)
            this.defaultSorting  = [...this.singleSub]
        }
    },
    created(){
        console.log(window.scrollY)
        if(this.$route.hash){
            this.pageSp = parseInt(this.$route.hash.replace(/#/, ''))
            console.log(this.pageSp)
        }

        if(this.singleSub && this.singleSub.category.slug === this.sub){
            return
        }
        this.$store.dispatch('catalog/loadSingleSub', this.sub)
    },
    methods: {
        sortGoodsPrice(){

            if(this.priceSort === null){
                this.priceSort = false
                this.singleSub.items.sort((a, b) =>{
                    return a.price.sl - b.price.sl
                })
            }else if(this.priceSort === false){
                this.priceSort = true
                this.singleSub.items.sort((a, b) =>{
                    return b.price.sl - a.price.sl
                })
            }else{
                this.priceSort = null
                this.$store.dispatch('catalog/loadSingleSub', this.sub)
            }
        },
        onChangePage(page){
            this.paginatedItems = page
            // this.$scrollTo('.catalog-wrapper', 400, options)
        },
        getPage(e){
            if(e.target.innerText === 'Начало' || e.target.innerText === 'В конец' || e.target.innerText === 'Пред.' || e.target.innerText === 'След.'){
                return
            }
            this.$router.push(`#${e.target.innerText}`) 
        }
    },
}
</script>