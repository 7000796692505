<template>
  <div class="good-item">
    <router-link
      tag="a"
      :to="`${catItem.url}`"
    >
      <div class="img-box">
        <div
          class="first-img"
          v-lazy:background-image="catItem.img"
        />
      </div>
    </router-link>
    <div class="under-img">
      <router-link
        tag="a"
        :to="`${catItem.url}`"
      >
        <h4 v-html="catItem.name" />
        <p
          class="grey-txt"
          v-html="catItem.description"
        />
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
    props: {
        catItem: {
            required: true,
            type: Object
        }
    }
}
</script>

<style scoped>
.good-item{
    margin-bottom: 40px;
}
.good-item h4{
    cursor: pointer;
}
.grey-txt{
    font-size: 13px !important;
    color: #333333cc !important;
    min-height: 216px;
}
.grey-txt span{
    color: #333333cc !important;
}
</style>