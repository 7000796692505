<template>
  <section id="files">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <h2>Файлы</h2>
        </div>
        <div
          class="col-lg-4"
          v-for="(item, index) in files"
          :key="index"
        >
          <a
            :href="item.link"
            target="_blank"
            rel="noopener noreferrer"
          >
            <h4>
              <span
                class="mdi"
                :class="item.icon"
              />
              {{ item.name }}
            </h4>
          </a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  export default {
    data(){
      return {
        files: [
          {
            link: 'https://sflora.ru/wp-content/uploads/2024/10/assortiment_spb.pdf',
            name: 'Ассортимент СПБ.pdf',
            icon: 'mdi-file-document-outline'
          },
          {
            link: 'https://sflora.ru/wp-content/uploads/2024/10/assortiment_regiony.pdf',
            name: 'Ассортимент Регионы.pdf',
            icon: 'mdi-file-document-outline'
          },
          {
            link: 'https://sflora.ru/wp-content/uploads/2024/10/assortiment_spb.docx',
            name: 'Ассортимент СПБ.docx',
            icon: 'mdi-file-document-outline'
          },
          {
            link: 'https://sflora.ru/wp-content/uploads/2024/10/assortiment_regiony.docx',
            name: 'Ассортимент Регионы.docx',
            icon: 'mdi-file-document-outline'
          },
          {
            link: 'https://sflora.ru/wp-content/uploads/2024/10/regiony_assortiment.zip',
            name: 'Регионы ассортимент.zip',
            icon: 'mdi-folder-zip-outline'
          },
          {
            link: 'https://sflora.ru/wp-content/uploads/2024/10/spb_assortiment.zip',
            name: 'СПБ ассортимент.zip',
            icon: 'mdi-folder-zip-outline'
          },
        ]
      }
    }
  }
</script>

<style scoped>

h4 span{
  font-size: 28px;
}
a h4{
  text-decoration: underline;
  font-size: 18px;
  margin-bottom: 24px;
}
a{
  transition: all .2s ease;
}
a:hover{
  opacity: .6;
}
</style>