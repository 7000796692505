<template>
    <div class="row good-item">
        <div class="col-lg-4">
                    <router-link tag="a" :to="`${catItem.url}`">
                        <div class="img-box">
                            <div class="first-img" v-lazy:background-image="catItem.img"></div>
                        </div>
                    </router-link>
        </div>
            <div class="col-lg-8">
                    <div class="under-img">
                        <router-link tag="a" :to="`${catItem.url}`">
                            <h4>{{catItem.name}}</h4>
                            <!-- ({{catItem.count}}) -->
                            <p class="grey-txt" v-html="catItem.description"></p>

                        </router-link>
                    </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        catItem: {
            required: true,
            type: Object
        }
    }
}
</script>

<style scoped>
.good-item{
    margin-bottom: 40px;
}
.good-item h4{
    cursor: pointer;
}
.grey-txt{
    font-size: 14px !important;
    color: #333333cc !important;
}
.grey-txt span{
    color: #333333cc !important;
}
</style>