import axios from 'axios'
import { API } from '../config';
import router from '../router'

const catalog = {
	namespaced: true,
	state: {
        regionModal: true,
        region: 'Санкт-Петербург и ЛО (самовывоз на весну)',
        categories: [],
        allGoods: [],
        singleCat: null,
        singleSub: null,
        good: null,
        popular: [],
        newGoods: [],
        searchResultsPr: [],
        searchResultsCt: [],
        loadSearch: false,
        merch: [],
        rules: false,
        detailsGoods: null,
        filterValues: {
            pochva: '',
            vremyacveteniya: '',
            otnoshenieksvetu: '',
            shirinakusta: '',
            vysotascvetonosom: ''
        },
        filtered: [],
        filterLoader: false,
        certificate: [],
        activeFilterLetter: '',
        filterParams: []
  	},
	mutations: {
        SET_FILTERS_PARAMS(state, filterParams){
            state.filterParams = filterParams
        },
		SET_REGION(state, region){
            state.region = region
            state.regionModal = false
        },
        SET_CATS(state, categories){
            state.categories = categories
        },
        SET_ALL(state, allGoods){
            state.allGoods = allGoods
        },
        SET_SINGLE_CAT(state, singleCat){
            state.singleCat = singleCat
        },
        SET_SINGLE_SUB(state, singleSub){
            state.singleSub = singleSub
        },
        SET_SINGLE_GOOD(state, good){
            state.good = good
        },
        SET_POP(state, popular){
            state.popular = popular
        },
        SET_NEW(state, newGoods){
            state.newGoods = newGoods
        },
        SET_SEARCH_PR(state, searchResultsPr){
            state.searchResultsPr = searchResultsPr
        },
        SET_SEARCH_CT(state, searchResultsCt){
            state.searchResultsCt = searchResultsCt
        },
        CLEAR_SEARCH(state){
            state.searchResultsPr = []
            state.loadSearch = false
        },
        SET_RULES(state, param){
            state.rules = param
        },
        SET_MERCH(state, merch){
            state.merch = merch
        },
        SET_CERT(state, certificate){
            state.certificate = certificate
        },
        SET_DETAIL_GOODS(state, detailsGoods){
            state.detailsGoods = detailsGoods
        },
        CLEAR_FILTERS(state){
            state.filterValues = {
                pochva: '',
                vremyacveteniya: '',
                otnoshenieksvetu: '',
                shirinakusta: '',
                vysotascvetonosom: ''
            }
        },
        SET_FILTERS(state, filtered){
            state.filtered = filtered
        },
        SET_LETTER(state, letter){
            state.activeFilterLetter = letter
        }
	},
	actions: {
        VALIDATE_REG({commit}, region){
            if(region){
                commit("SET_REGION", region)
                localStorage.setItem("region", JSON.stringify(region));
            }
        },
		changeRegion({commit, state, dispatch}, region){
            commit("SET_REGION", region)
            localStorage.setItem("region", JSON.stringify(region));
            dispatch('cart/clearCart', null ,{root: true})
            state.rules = true
            // router.go()
        },
        loadCategories({commit}){
            axios.get(`${API}get/categories`)
            .then(res =>{
                commit("SET_CATS", res.data)
            })
        },
        loadAllGoods({commit}){
            axios.get(`${API}get/all/product`)
            .then(res =>{
                // console.log(res.data.items) 
                commit("SET_ALL", res.data.items)
                
                let arrayWithMi = res.data.items.filter(item =>{
                    return item.quantity.sl < 0 || item.quantity.rg < 0
                })


                for(let i = 0; i < arrayWithMi.length; i++){
                    // console.log(`${i + 1}. ${arrayWithMi[i].name}`)
                }
            })
        },
        loadSingleCat({commit, state}, category){
            state.singleCat = null
            axios.get(`${API}get/category/${category}`)
            .then(res =>{
                // console.log(res.data)
                commit("SET_SINGLE_CAT", res.data)
            })
        },
        loadMerch({commit, state}){
            state.merch = null
            axios.get(`${API}get/category/pechatnaya-produkcziya`)
            .then(res =>{
                commit("SET_MERCH", res.data)
            })

        },
        loadSingleSub({commit, state}, subcat){
            state.singleSub = null
            axios.get(`${API}get/category/${subcat}`)
            .then(res =>{
                // console.log(res)
                commit("SET_SINGLE_SUB", res.data)
            })
        },
        async loadSingleGood({commit, state}, good){
            state.good = null
            await axios.get(`${API}get/product/${good}`)
            .then(res =>{
                // console.log(res.data.data)
                commit("SET_SINGLE_GOOD", res.data.data)
            })
            .catch(err =>{
                console.log(router.push('/400'))
            })
        },
        loadPopular({commit}){
            axios.get(`${API}get/popular/product`)
            .then(res =>{
                // console.log(res.data)
                commit("SET_POP", res.data.items)
            })
        },
        loadNew({commit}){
            axios.get(`${API}get/new/product`)
            .then(res =>{
                // console.log(res.data)
                commit("SET_NEW", res.data.items)
            })
        },
        loadCert({commit}){
            axios.get(`${API}get/category/sertifikaty`)
            .then(res =>{
                // console.log(res.data)
                commit("SET_CERT", res.data.items)
            })
        },
        async goSearch({commit, state}, search){
            await axios.get(`${API}get/search/${search}`)
            .then(res =>{
                if(res.data.products){
                    commit("SET_SEARCH_PR", res.data.products)
                }
                if(res.data.cats){
                    commit("SET_SEARCH_CT", res.data.cats)
                }
            })
        },
        clearSearch({commit}){
            commit("CLEAR_SEARCH")
        },
        clearCart({commit, rootState}){
            console.log(rootState)
        },
        controlRules({commit}, param){
            commit("SET_RULES", param)
        },
        loadDetailGoods({commit, state}, payload){
            state.detailsGoods = null

            axios
            .post(`https://sflora.ru/wp-json/sf/v1/get/products/attr/${payload.urlParam}`, payload.objParam)
            .then(res =>{
                console.log(res.data)
                commit("SET_DETAIL_GOODS", res.data)
            })
        },
        clearFilters({commit}){
            commit("CLEAR_FILTERS")
        },
        filterGoods({commit, state}, param){
            state.filterLoader = true
            state.filtered = []
            let url = ''
            if(param.url){
                url = `${API}get/products/attr/${param.url}`
            }else{
                url = `${API}get/products/attr`
            }

            axios
            .post(url, param.filters)
            .then(res => {
                state.filterLoader = false
                if(res.data.count == 0){
                    commit("SET_FILTERS", null)
                    return
                }
                commit("SET_FILTERS", res.data.items)
               
            })
        },
        setActiveFilterLetter({commit}, letter){
            commit("SET_LETTER", letter)
        },
        loadFilterParams({commit}){
            axios
            .get(`${API}get/terms`)
            .then(res =>{
                commit("SET_FILTERS_PARAMS", res.data)
            })
            
        }
	},
	getters: {
        getSearchResultsCt(state){
            return state.searchResultsCt
        },
        getFilterLoader(state){
            return state.filterLoader
        },
        getFilterParams(state){
            return state.filterParams
        },
        getRegion(state){
            return state.region
        },
        getCategories(state){
            return state.categories
        },
        getAllGoods(state){
            return state.allGoods
        },
        getSingleCat(state){
            return state.singleCat
        },
        getSingleSub(state){
            return state.singleSub
        },
        getSingleGood(state){
            return state.good
        },
        getPopular(state){
            return state.popular
        },
        getNewGoods(state){
            return state.newGoods
        },
        getSearchResultsPr(state){
            return state.searchResultsPr
        },
        getLoadSearch(state){
            return state.loadSearch
        },
        getRegionModal(state){
            return state.regionModal
        },
        getRules(state){
            return state.rules
        },
        getMerch(state){
            return state.merch
        },
        getDetailsGoods(state){
            return state.detailsGoods
        },
        getFilterValues(state){
            return state.filterValues
        },
        getFiltered(state){
            return state.filtered
        },
        getCertificate(state){
            return state.certificate
        },
        getActiveFilterLetter(state){
            return state.activeFilterLetter
        }
	}
}

export default catalog


