import axios from 'axios'
import { API } from '../config';
import router from '../router'

const content = {
	namespaced: true,
	state: {
        news: [],
        simpleNews: [],
        gallery: [],
        priceList: null
  	},
	mutations: {
		SET_NEWS(state, news){
            state.news = news
        },
        SET_SIMPLE_NEWS(state, simpleNews){
            state.simpleNews = simpleNews
        },
        SET_GALLERY(state, gallery){
            state.gallery = gallery
        },
        SET_PRICE(state, priceList){
            state.priceList = priceList
        }
	},
	actions: {
        loadNews({commit}){
            axios
            .get(`${API}get/vknews?count=10`)
            .then(res =>{
                commit("SET_NEWS", res.data)
            })
        },
        loadSimpleNews({commit}){
            axios
            .get(`${API}get/news`)
            .then(res =>{
                commit("SET_SIMPLE_NEWS", res.data.data)
            })
        },
        loadGallery({commit}){
            axios.get(`${API}get/vpitomnik/`)
            .then(res =>{
                commit("SET_GALLERY", res.data.data)
            })
        },
        loadPriceList({commit}){
            axios.get(`${API}get/menu/price`)
            .then(res =>{
                commit("SET_PRICE", res.data)
            })
        }
	},
	getters: {
        getPriceList(state){
            return state.priceList
        },
        getNews(state){
            return state.news
        },
        getSimpleNews(state){
            return state.simpleNews
        },
        getGallery(state){
            return state.gallery
        }
	}
}

export default content


