<template>
    <div>
        <Gallery />
    </div>
</template>

<script>
import Gallery from '../components/Gallery.vue'

export default{
    components: {Gallery}
}
</script>
