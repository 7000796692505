<template>
    <section id="enter">
        <div class="container">
            <div class="row">
                <div class="col-lg-4 col-lg-offset-4">
                    <form @submit.prevent="login" class="text-center">
                        <h3>Войти в личный кабинет</h3>
                        <br><br>
                        <input type="text" placeholder="Логин" v-model="name"
                        :class="{errorInp : $v.name.$dirty && !$v.name.required}">
                        <input type="password" placeholder="Пароль" v-model="pass"
                        :class="{errorInp : $v.pass.$dirty && !$v.pass.required}">
                        <p class="small-txt">
                            Забыли пароль?
                            <router-link tag="a" to="/my-account/lost-password">Восстановить</router-link>
                        </p>
                        <button class="to-cart" v-if="!load">Войти</button>
                        <loader v-else />
                        <p class="err" v-if="loginError"><br> Ошибка входа, попробуйте еще раз</p>
                        <br><br>
                        <p class="small-txt">
                            Еще нет учетной записи? <br>
                            <router-link tag="a" to="/signup">Зарегистрироваться</router-link>
                        </p>
                    </form>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import loader from '../components/ui/loader.vue'
import { required } from "vuelidate/lib/validators";

export default {
    components: {loader},
    data(){
        return{
            name: '',
            pass: '',
            load: false
        }
    },
    computed: {
        ...mapGetters({
            loginError: "auth/getLoginErr",
            user: "auth/getAuthenticated"
        })
    },
    validations: {
		name: {
			required
		},
		pass:{
			required
		},
    },
    methods: {
        ...mapActions({
                AUTH_REQUEST: "auth/AUTH_REQUEST",
        }),
        login(){

            if(this.$v.$invalid) {
                this.$v.$touch();
                return;
            }

            this.load = true

            let form = {
                username: this.name,
                password: this.pass,
            };

            this.AUTH_REQUEST(form).then(() => {
                    this.load = false
                    if(this.loginError){
                        return
                    }else{
                        this.$router.replace("/profile");
                    }
                });
        }
    }
}
</script>

<style scoped>
.small-txt a{
    color: #9BBE3B;
}
.err{
    color: red;
}
.errorInp{
    border: 1px red solid !important;
}
</style>